import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "../../common/components/spinner";
import Helper from "../../common/consts/Helper";

export default function Slot(props) {
  const [data, setData] = useState({
    slots: "",
    slotId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingYear, setIsLoadingYear] = useState(false);
  const [year, setYear] = useState("");
  const [yearId, setYearId] = useState(0);

  useEffect(() => {
    fetchData();
    fetchYearData();
  }, []);

  let { slots } = data;

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-6">
          <div className="card card-user">
            <div className="card-header">
              <h5 className="card-title">
                <span
                  onClick={() => {
                    props.history.push("/staff");
                  }}
                  className="cursor-pointer"
                >
                  <i className="fa fa-chevron-left mr-3" />
                </span>
                Update Calls Per Slot
              </h5>
            </div>
            <div className="card-body">
              <form onSubmit={updateSlots}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Slots</label>
                      <input
                        type="number"
                        className="form-control text-capitalize"
                        placeholder="Ex - 25"
                        name="slots"
                        required
                        value={slots.toString()}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="update ml-auto mr-auto">
                    <button
                      type="submit"
                      className="btn btn-primary btn-round"
                      disabled={isLoading}
                    >
                      {isLoading && <Spinner />}Update Calls
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card card-user">
            <div className="card-header">
              <h5 className="card-title">Update Year of Tax</h5>
            </div>
            <div className="card-body">
              <form onSubmit={updateApplication}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Year Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex - Pay Tax for 2021-2022"
                        name="taxYear"
                        required
                        value={year.toString()}
                        onChange={(e) => {
                          setYear(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="update ml-auto mr-auto">
                    <button
                      type="submit"
                      className="btn btn-primary btn-round"
                      disabled={isLoadingYear}
                    >
                      {isLoadingYear && <Spinner />}Update Year Description
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function fetchYearData() {
    setIsLoading(true);
    let res = Helper("/application", "GET");
    res
      .then((response) => {
        if (response.error) toast.error(response.error);
        else if (
          response?.appInfo &&
          response?.appInfo[0] &&
          response?.appInfo[0].year
        ) {
            setYear(response.appInfo[0].year);
            setYearId(response.appInfo[0]?._id)
        }
      })
      .catch((error) => null)
      .finally(() => setIsLoading(false));
  }
  function fetchData() {
    setIsLoading(true);
    let res = Helper("/slots", "GET");
    res
      .then((response) => {
        if (response.error) toast.error(response.error);
        else {
          setData({
            ...data,
            slots: response.slotsInfo[0].slots,
            slotId: response.slotsInfo[0]._id,
          });
          toast.success(response.message);
        }
      })
      .catch((error) => null)
      .finally(() => setIsLoading(false));
  }
  function updateApplication(e) {
    e.preventDefault();
    setIsLoadingYear(true);

    let res = Helper("/application", "PUT", {
        year,
        id: yearId
    });
    res
      .then((response) => {
        if (response.error) toast.error(response.error);
        else toast.success(response.message);
      })
      .catch((error) => null)
      .finally(() => setIsLoadingYear(false));
  }
  function updateSlots(e) {
    e.preventDefault();
    setIsLoading(true);

    let res = Helper("/slots", "PATCH", {
      ...data,
      slots: +data.slots,
    });
    res
      .then((response) => {
        if (response.error) toast.error(response.error);
        else toast.success(response.message);
      })
      .catch((error) => null)
      .finally(() => setIsLoading(false));
  }
  function handleChange(e) {
    let { value, name } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  }
}
