import React, { Fragment, useEffect, useState } from 'react';
import { Chart, Line, Point, Tooltip, Axis } from "bizcharts";
import { DatePicker } from 'antd';

//custom components
import Spinner from '../../common/components/spinner';

//constnats
import moment from 'moment';
import { toast } from 'react-toastify';
import Helper from '../../common/consts/Helper';


const { RangePicker } = DatePicker;

export default function OrderChart() {

    const [dateRange, setDateRange] = useState({
        startDate: moment().subtract(1, 'months'),
        endDate: moment(),
    });
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetchOrders();
    }, [dateRange]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <span className="mr-3">Date Range:</span>
                    <RangePicker
                        onChange={(value) => {
                            let startDate = '';
                            let endDate = '';
                            if (value.length === 2) {
                                startDate = value[0];
                                endDate = value[1]
                            }
                            setDateRange({
                                ...dateRange,
                                startDate,
                                endDate
                            })
                        }}
                        disabled={isLoading}
                        value={[dateRange.startDate, dateRange.endDate]}
                    />
                    {
                        isLoading && <span className="ml-3"><Spinner /></span>
                    }
                </div>
            </div>
            <Chart
                padding={[10, 20, 50, 50]}
                autoFit
                height={350}
                data={orders}
                scale={{ value: { min: 0 } }}
            >
                <Line position="date*orders" />
                <Point position="date*orders" />
                <Tooltip showCrosshairs lock triggerOn='hover' />
                <Axis name='value' title={{
                    position: 'center'
                }} />
            </Chart>
        </Fragment>
    )

    function fetchOrders() {
        setIsLoading(true);
        let res = Helper('/fetch_orders_count', 'POST', {
            ...dateRange,
            startDate: moment(dateRange?.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange?.endDate).format('YYYY-MM-DD'),
        });
        res.then((response) => {
            if (response.error) toast.error(response.error);
            else {
                let newOrders = []
                for (const order of response.orders) {
                    newOrders.push({
                        date: moment(order._id).format('Do MMMM, YYYY'),
                        orders: order.totalOrders
                    })
                }
                setOrders(newOrders)
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false))
    }
}