import React, { useState } from 'react';

import Spinner from '../../common/components/spinner';
import { toast } from 'react-toastify';
import { Select } from 'antd';

//helper
import Helper from '../../common/consts/Helper';

const { Option } = Select;

export default function Login(props) {

    //states
    const [data, setData] = useState({
        email: '',
        password: '',
        userType: 1
    });
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <div className="sidenav">
                <div className="login-main-text">
                    <h1 className="text-light">Protax Canada</h1><br /> <h2 className="text-light">Welcomes you!!</h2>
                    <p className="text-light">Login here to access Admin Panel.</p>
                </div>
            </div>
            <div className="main">
                <div className="col-md-6 col-sm-12">
                    <div className="login-form">
                        <form
                            onSubmit={login}
                        >
                            <div
                                className="form-group"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <label>Type</label>
                                <Select
                                    defaultValue={1}
                                    style={{ width: 120 }}
                                    onChange={(userType) => { setData({ ...data, userType }) }}
                                >
                                    <Option value={1}>Admin</Option>
                                    <Option value={3}>Staff</Option>
                                </Select>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleChange}
                                    value={data.email}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    value={data.password}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn bg-primary btn-custom"
                                disabled={isLoading}
                            >
                                {isLoading && <Spinner />}Login
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
    function handleChange(e) {
        let { value, name } = e.target;
        setData({ ...data, [name]: value });
    }
    function login(e) {
        e.preventDefault();
        setIsLoading(true);
        let res = Helper('/admin/signin', 'POST', data);
        res.then((response)=>{
            if(response.error) toast.error(response.error);
            else{
                localStorage.setItem('access_token', response.accessToken);
                localStorage.setItem('role', response.userInfo[0].userType);
                localStorage.setItem('user_info', JSON.stringify(response.userInfo[0]));
                props.history.push('/');
            }
        })
        .catch((error)=>{})
        .finally(()=>{setIsLoading(false)});
    }
}