import React from 'react';

export default function Spinner() {
    return (
        <div className="spinner-grow mr-2" style={{
            width: '0.75rem',
            height: '0.75rem'
        }} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}