import React from 'react';

//antd components
import { List, Typography } from 'antd';
import { MinusSquareOutlined } from '@ant-design/icons';

//consts
import { toast } from 'react-toastify';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

function DependentsList(props) {

    let { dependants, receiptsChecklist, handleChange } = props;

    return (
        <div className="modal fade" id="dependentList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Dependents List</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <List
                            bordered
                            dataSource={dependants}
                            renderItem={(item, index) => (
                                <List.Item className="text-capitalize">
                                    <Typography.Text>{index + 1}</Typography.Text>
                                    <Typography.Text>
                                        <span>
                                            {item.firstName} {item.middleName} {item.lastName}
                                        </span>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <span>
                                            {item.relation}
                                        </span>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <MinusSquareOutlined className="cursor-pointer" onClick={() => { deleteDependant(index) }} />
                                    </Typography.Text>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )

    function deleteDependant(index) {
        let confirmation = window.confirm("Are you sure you want to delete this dependant?");
        if (confirmation) {
            let newDependants = dependants.slice();
            if (newDependants[index].relation === 'children') {
                let newReceipts = receiptsChecklist.slice();
                let rIndex = newReceipts.findIndex(e => e.type === 'child');
                if (rIndex !== -1) {
                    let cIndex = newReceipts[rIndex].childs.findIndex(e => e.id === newDependants[index].id);
                    if (cIndex !== -1) {
                        newReceipts[rIndex].childs.splice(cIndex, 1);
                        handleChange('receiptsChecklist', newReceipts);
                    }
                }
            }
            newDependants.splice(index, 1);
            handleChange('dependants', newDependants);
            toast.info('Dependant Deleted!!');
        }
    }
}

const mapStateToProps = () => state => ({
    dependants: state.Pickup.dependants,
    receiptsChecklist: state.Pickup.receiptsChecklist
});

const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(DependentsList);