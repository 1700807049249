import {
    BASE_URL
} from './Config';

const Helper = (url, method, data, isFormData = false) => {
    return (
        fetch(BASE_URL + url, {
            method: method,
            body: isFormData ? (data) : (JSON.stringify(data)),
            headers: getHeaders(isFormData)
        }).then((response) => {
            if (response.status === 401) {
                localStorage.removeItem('access_token');
                window.location.href = "/";
            } else return response.json()
        }).catch((error) => {
            // useToasts.addToast(ErrorMessages.internetConnection, { appearance: 'error' })
        })
    )
}

const getHeaders = (isFormData) => {
    if (isFormData) {
        return {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
        }
    } else {
        return {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            'Content-Type': 'application/json'
        }
    }
}

export default Helper;