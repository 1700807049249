import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

//constants
import Helper from '../../common/consts/Helper';
import { toast } from 'react-toastify';

//custom components
import OrderChart from './OrdersChart';
import SalesChart from './SalesChart';

export default function DashboardStaff(props) {

    const [stats, setStats] = useState({
        totalOrders: 0,
        ordersCompleted: 0,
        ordersPending: 0,
        ordersEFiled: 0
    });

    useEffect(() => {
        fetchStats();
    }, []);

    let { totalOrders, ordersCompleted, ordersPending, ordersEFiled } = stats;

    return (
        <div className="content">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-globe text-warning"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Orders Assigned</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={totalOrders}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-money-coins text-success"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Orders Completed</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={ordersCompleted}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-vector text-danger"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Orders Pending</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={ordersPending}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-vector text-danger"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Orders E-Filed</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={ordersEFiled}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="card-header ">
                            <h5 className="card-title">Orders - Chart</h5>
                            <p className="card-category">According to Date Range</p>
                        </div>
                        <div className="card-body ">
                            <OrderChart />
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-history"></i> Updated 3 minutes ago
                </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="card-header ">
                            <h5 className="card-title">Sales Statistics</h5>
                            <p className="card-category">Current Performance</p>
                        </div>
                        <div className="card-body ">
                            <SalesChart />
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-calendar"></i> Sales Done in Date Range
                </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )


    function getStaffId() {
        let userInfo = localStorage.getItem('user_info');
        let id = '';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            id = userInfo._id;
        }
        return id;
    }

    function fetchStats() {
        let body = {
            userId: getStaffId()
        }
        let res = Helper('/fetch_staff_by_id', 'POST', body);
        res.then((response) => {
            if (response.error) toast.error(response.error)
            else {
                setStats({
                    ...stats,
                    totalOrders: response.orderInfo?.length,
                    ordersCompleted: response.orderInfo?.filter(e => e.status === 3)?.length,
                    ordersEFiled: response.orderInfo?.filter(e => e.status === 4)?.length,
                    ordersPending: response.orderInfo?.filter(e => (e.status !== 3 && e.status !== 4))?.length
                })
                // setOrders(response.orderInfo);
                // setUserInfo(response.userInfo[0]);
            }
        })
            .catch((error) => null)
    }
}