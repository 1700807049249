import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function SideBar() {

    let location = useLocation();

    const routeStaff = [
        {
            name: 'Dashboard',
            path: '/',
            icon: 'fas fa-home'
        },
        {
            name: 'Orders',
            path: `/staff/order/${getStaffId()}`,
            icon: 'fas fa-list'
        },
        {
            name: 'Nofication',
            path: '/notification_view',
            icon: 'fas fa-bell'
        }
    ]

    const routesAdmin = [
        {
            name: 'Dashboard',
            path: '/',
            icon: 'fas fa-home'
        },
        {
            name: 'Staff',
            path: '/staff',
            icon: 'fas fa-user'
        },
        {
            name: 'Orders',
            path: '/order',
            icon: 'fas fa-list'
        },
        {
            name: 'Users',
            path: '/user',
            icon: 'fas fa-users'
        },
        {
            name: 'Slots & Other Data',
            path: '/slot',
            icon: 'fas fa-glass-whiskey'
        },
        {
            name: 'Nofication',
            path: '/notification_view',
            icon: 'fas fa-bell'
        }
    ]

    function getStaffId() {
        let userInfo = localStorage.getItem('user_info');
        let id = '';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            id = userInfo._id;
        }
        return id;
    }

    return (
        <div className="sidebar" data-color="white" data-active-color="danger">
            <div className="logo">
                <a
                    href="https://www.protaxcanada.ca/"
                    target="_blank"
                    className="simple-text logo-mini"
                >
                    <div className="logo-image-small">
                        <img src="../assets/img/logo-small.png" />
                    </div>
                </a>
                <a
                    href="https://www.protaxcanada.ca/"
                    target="_blank"
                    className="simple-text logo-normal"
                >
                    Protax Canada
            </a>
            </div>
            <div className="sidebar-wrapper">
                <ul className="nav">
                    {
                        (+localStorage.getItem('role') === 1 ? routesAdmin : routeStaff).map((v, i) => {
                            return (
                                <li className={v.path === location.pathname && 'active'} key={i}>
                                    <Link
                                        to={v.path}
                                        onClick={() => { localStorage.setItem('currentPage', v.name) }}
                                    >
                                        <i className={v.icon}></i>
                                        <p>{v.name}</p>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}