import React, { Fragment, useState } from 'react';

//ant d components
import { DatePicker, Select } from 'antd';

//helpers
import { allDisabilityTaxCredit } from '../../common/consts/ProtaxHelpers';
import Helper from '../../common/consts/Helper';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

//consts
import moment from 'moment';
import { BASE_URL } from '../../common/consts/Config';
import { toast } from 'react-toastify';

import Spinner from '../../common/components/spinner';

const { Option } = Select;

function OtherChecklist(props) {

    const [isUploading, setIsUploading] = useState({
        isUploading: false,
        isUploadingIndex: null
    });

    let {

        //basic details
        otherDocChecklist,
        firstName,

        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Other Documents</span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {
                        otherDocChecklist.map((checklist, index) => {
                            return (
                                <div key={index}>
                                    <div>
                                        <span>{index + 1}. </span>
                                        {checklist.title}
                                    </div>
                                    <Select
                                        placeholder="Select"
                                        className="text-capitalize  ml-2 mt-2 mb-2"
                                        style={{ minWidth: '140px' }}
                                        value={checklist.checked}
                                        onChange={(value) => {
                                            let newSlips = otherDocChecklist.slice();
                                            newSlips[index].checked = value;
                                            handleChange('otherDocChecklist', newSlips);
                                        }}
                                    >
                                        {
                                            allDisabilityTaxCredit.map((item, index) => {
                                                return (
                                                    <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    {
                                        (checklist.checked && checklist.type === 'images') ? (
                                            <div>
                                                <div className="checklistImageContainer">
                                                    {
                                                        checklist.images.map((image, ci) => {
                                                            return (
                                                                <div
                                                                    className="checklistImage" key={ci}>
                                                                    <img src={BASE_URL + image} className="checklistImage ml-0" />
                                                                    <div
                                                                        className="checklistCross"
                                                                        onClick={() => {
                                                                            deleteImage(index, ci)
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-times" />
                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                    {
                                                        (isUploading.isUploading && isUploading.isUploadingIndex === index) ? (
                                                            <div className="checklistImage bg-light d-flex align-items-center justify-content-center">
                                                                <Spinner />
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Upload</label>
                                                        <div className="input-group mb-3">
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    accept="image/*"
                                                                    onChange={(e) => { handleImage(e, index) }}
                                                                />
                                                                <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="row">
                                        {
                                            checklist.checked && checklist.type === 'child' && (
                                                checklist.childs.map((child, ci) => {
                                                    return (
                                                        <div className="col-md-4" key={ci}>
                                                            <div className="form-group">
                                                                <label className="text-capitalize text-bold">{child.firstName}</label>
                                                                <input
                                                                    value={child.amount}
                                                                    type="text"
                                                                    className="form-control text-capitalize"
                                                                    placeholder="Amount"
                                                                    required
                                                                    name="firstName"
                                                                    onChange={(e) => {
                                                                        handleChildValue(e.target.value, index, ci)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>

                                    {
                                        checklist.checked && checklist.type === 'political' && (

                                            checklist.typeOfContribution.map((contribution, contIndex) => {
                                                return (
                                                    <div
                                                        className="row"
                                                        key={contIndex}
                                                    >
                                                        <div className="col-sm-12 col-md-3" key={index}>
                                                            <div className="form-group">
                                                                <label>{contribution.title}</label>
                                                                <Select
                                                                    placeholder="Marital Status"
                                                                    className="text-capitalize w-100"
                                                                    value={contribution.checked}
                                                                    onChange={(value) => {
                                                                        handleCheckboxPolitical(value, index, contIndex, 'checked')
                                                                    }}
                                                                >
                                                                    {
                                                                        allDisabilityTaxCredit.map((item, index) => {
                                                                            return (
                                                                                <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-3">
                                                            <div className="form-group">
                                                                <label>Amount</label>
                                                                <input
                                                                    value={contribution.amount}
                                                                    type="text"
                                                                    className="form-control text-capitalize"
                                                                    placeholder="Amount"
                                                                    required
                                                                    name="amount"
                                                                    onChange={(e)=>{handleCheckboxPolitical(e.target.value, index, contIndex, 'amount')}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                </div>
                            )
                        }
                        )}
                </div>
            </div>
        </Fragment>
    )
    function handleCheckboxPolitical(value, checklistIndex, typeIndex, variable) {
        let newReceipts = otherDocChecklist.slice();
        newReceipts[checklistIndex].typeOfContribution[typeIndex][variable] = value;
        handleChange('otherDocChecklist', newReceipts);
    }
    function handleChildValue(value, checklistIndex, childIndex) {
        let newReceipts = otherDocChecklist.slice();
        newReceipts[checklistIndex].childs[childIndex].amount = value;
        handleChange('otherDocChecklist', newReceipts);
    }
    function deleteImage(index, ci) {
        let confirmation = window.confirm('Are you sure you want to delete this image?');
        // console.log(confirmation, index)
        if (confirmation) {
            let newChecklist = otherDocChecklist.slice();
            newChecklist[index].images.splice(ci, 1);
            handleChange('otherDocChecklist', newChecklist);
        }
    }
    function handleImage(e, index) {
        let { files } = e.target;
        if (files.length > 0) {
            let body = new FormData();
            body.append('image', files[0]);
            setIsUploading({
                ...isUploading,
                isUploading: true,
                isUploadingIndex: index
            });
            let res = Helper('/image', 'POST', body, true);
            res.then((response) => {
                if (response.message) {
                    toast.success(response.message);
                    saveImage(response.data, index);
                }
                else if (response.error) toast.error(response.error.toString())
            })
                .catch((error) => {
                    toast.error("Something went wrong while uploading the image, Please try again later!!")
                })
                .finally(() => {
                    setIsUploading({
                        ...isUploading,
                        isUploading: false,
                        isUploadingIndex: null
                    });
                })
        }
    }

    function saveImage(url, index) {
        let newChecklist = otherDocChecklist.slice();
        newChecklist[index].images.push(url);
        handleChange('otherDocChecklist', newChecklist);
    }
}


const mapStateToProps = () => state => ({

    //basic details
    otherDocChecklist: state.Pickup.otherDocChecklist,
    dependants: state.Pickup.dependants,
    firstName: state.Pickup.firstName,

});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(OtherChecklist);