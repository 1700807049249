import React, { useEffect, useState, useRef } from 'react';

//components
import { Select } from 'antd';
import Spinner from '../spinner';

//helpers
import _ from 'lodash';
import Helper from '../../consts/Helper';
import { toast } from 'react-toastify';
import { StatusString } from '../../consts/ProtaxHelpers';

const { Option } = Select;

export default function OrderStatus(props) {

    let { fetchOrders, currentStatus, setCurrentStatus, orderId, setOrderId, orderFullName } = props;

    const closeRef = useRef();

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <div class="modal fade" id="OrderStatusModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Order Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h6 className="text-bold">File - {orderFullName}</h6>
                            </div>
                            <div className="col-12">
                                <label>Select Status</label>
                            </div>
                            <div className="col-12">
                                <Select
                                    style={{ minWidth: 140 }}
                                    placeholder="Status"
                                    value={currentStatus.toString()}
                                    className="text-capitalize"
                                    onChange={(value) => {
                                        setCurrentStatus(value)
                                    }}
                                >
                                    {
                                        Object.keys(StatusString).map((status, index) => {
                                            return (
                                                <Option
                                                    className="text-capitalize"
                                                    key={index}
                                                    value={status}
                                                >
                                                    {StatusString[status]}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            ref={closeRef}
                        >Close</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                submit()
                            }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spinner /> : null}Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    function submit() {
        if (!currentStatus) {
            toast.error("Please select a status to continue!!");
        }
        else {
            setIsSubmitting(true);
            let res = Helper('/order', 'PATCH', {
                orderId,
                status: currentStatus
            })
            res.then((response) => {
                if (response.error) toast.error(response.error)
                else {
                    toast.success(response.message)
                    setCurrentStatus('');
                    setOrderId('');
                    fetchOrders();
                    closeRef.current.click();
                }
            })
                .catch((error) => null)
                .finally(() => setIsSubmitting(false))
        }
    }
}