import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Helper from '../../consts/Helper';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import Spinner from '../spinner';

const { Option } = Select;

export default function AssignOrder(props) {

    let orders = props.assignStaff;
    let { fetchOrders } = props;

    const closeRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [staff, setStaff] = useState([]);
    const [user, setUser] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        fetchStaff();
    }, []);

    return (
        <div class="modal fade" id="AssignModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Assign Order</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <label>Select Staff</label>
                            </div>
                            <div className="col-12">
                                <Select
                                    style={{ minWidth: 140 }}
                                    placeholder="Staff"
                                    className="text-capitalize"
                                    loading={isLoading}
                                    onChange={(value) => {
                                        setUser(value);
                                    }}
                                >
                                    {
                                        staff.map((user, index) => {
                                            return (
                                                <Option className="text-capitalize" key={index} value={user._id}>{user.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            ref={closeRef}
                        >Close</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                submit()
                            }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spinner /> : null}Assign
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    function submit() {
        if (user === '') {
            toast.error("Please select a staff member to continue!!");
        }
        else {
            setIsSubmitting(true);
            let res = Helper('/orders', 'PATCH', {
                userId: user,
                orders
            })
            res.then((response) => {
                if (response.error) toast.error(response.error)
                else {
                    fetchOrders();
                    closeRef.current.click();
                }
            })
                .catch((error) => null)
                .finally(() => setIsSubmitting(false))
        }
    }
    function fetchStaff() {
        setIsLoading(true);
        let body = {
            userType: 3,
            limit: 100,
            skip: 0
        }
        let res = Helper('/users', 'POST', body);
        res.then((response) => {
            if (response.error) toast.error(response.error)
            else {
                setStaff(response.users);
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false));
    }
}