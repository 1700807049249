import React, { useState } from "react";
import { Button, Image } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import NotFound from "../../../assets/images/notFound.png";
import { BASE_URL } from "../../../common/consts/Config";
import heic2any from "heic2any";
import Helper from "../../../common/consts/Helper";
import TFormViewer from "./TFormViewer";

export default function QuestionsForm(props) {
  let { checklist, title, tFormVariables } = props;

  const [currentForm, setCurrentForm] = useState(false);
  const [tForm, setTForm] = useState([]);
  return (
    <>
      <div className="row">
        <div className="col-12 mb-2">
          <h5>{title}</h5>
        </div>
      </div>
      <div className="row">
        {checklist.map((item, index) => {
          return (
            <div className="col-12" key={index}>
              <div>
                <span className="mr-2 text-bold">{index + 1}</span>
                {item.title}
              </div>
              <div className="text-bold">
                {item.checked ? "Checked" : "Not Checked"}
              </div>
              <div
                className="p-1 pb-3 px-3"
                style={{ overflowX: "scroll" }}
                key={index}
              >
                {item.checked &&
                  item.type === "inputs" &&
                  item.inputs.map((inputDetails, ci) => {
                    return (
                      <div className="row" key={ci}>
                        <div className="col-12">
                          <div className="d-flex">
                            <span className="text-bold">
                              {inputDetails.placeholder} -
                            </span>
                            <span>{` $${inputDetails.value}`}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {item.checked &&
                  item?.type === "images" &&
                  item?.images?.map((image, index) => {
                    if (image.endsWith(".pdf")) {
                      return (
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(BASE_URL + image, "_blank");
                          }}
                          className="protax-btn mt-2"
                        >
                          View PDF
                        </Button>
                      );
                    } else {
                      if (image.endsWith(".heic") || image.endsWith(".HEIC")) {
                        convertHeicToJpg(image);
                        return <div>heic format image uploaded</div>;
                      } else {
                        return (
                          <>
                            <Image
                              className="img-fluid mr-2"
                              key={index}
                              src={BASE_URL + image}
                              width={200}
                              onError={(e) => (
                                (e.target.onerror = null),
                                (e.target.src = NotFound)
                              )}
                            />

                            <Button
                              type="primary"
                              className="download-file-btn ml-2 mr-2"
                              icon={<DownloadOutlined />}
                              onClick={() => {
                                downloadURI(BASE_URL + image, title);
                              }}
                            />
                          </>
                        );
                      }
                    }
                  })}

                {item?.type === "child" &&
                  item?.childs?.map((child, ci) => {
                    return (
                      <div className="col-12" key={ci}>
                        <div className="d-flex">
                          <span className="text-bold">{child.firstName} -</span>
                          <span>{` $${child.amount}`}</span>
                        </div>
                      </div>
                    );
                  })}

                {item?.checked && item?.type === "political" && (
                  <div className="row">
                    {item.typeOfContribution.map((cont, contInd) => {
                      if (cont.checked) {
                        return (
                          <div className="col-12" key={contInd}>
                            <div className="d-flex">
                              <span className="text-bold">{cont.title} -</span>
                              <span>{` $${cont.amount}`}</span>
                            </div>
                          </div>
                        );
                      } else return null;
                    })}
                  </div>
                )}

                {item?.checked && item.showEmailButton && (
                  <div>
                    {Object.keys(tFormVariables)
                      ?.filter((e) =>
                        e?.includes(getDocumentInclude(item.documentType))
                      )
                      ?.map((form, index) => {
                        if (tFormVariables[form])
                          return (
                            <Button
                              type="primary"
                              className="download-file-btn ml-2 mr-2"
                              key={index}
                              onClick={() => {
                                setCurrentForm(true);
                                setTForm(tFormVariables[form]);
                              }}
                            >
                              {getDocumentInclude(item.documentType)} -{" "}
                              {index + 1}
                            </Button>
                          );
                        else return null;
                      })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <TFormViewer
        visible={currentForm}
        onClose={() => {
          setCurrentForm(false);
        }}
        fields={tForm}
      />
    </>
  );

  function getDocumentInclude(docType) {
    let title = "T777";
    if (docType === 1) {
      title = "T2125";
    } else if (docType === 3) {
      title = "T776";
    }
    return title;
  }

  async function convertHeicToJpg(url) {
    let res = Helper(url, "GET");
    res
      .then((res) => {
        return res.blob();
      })
      .then((blob) =>
        heic2any({
          blob,
        })
      )
      .then((conversionResult) => {
        var url = URL.createObjectURL(conversionResult);
      })
      .catch((e) => {});
  }
  function downloadURI(uri, name) {
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute("download", name);
    link.setAttribute("target", "_blank");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
