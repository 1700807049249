import React, { Fragment } from 'react';

//ant d components
import { DatePicker, Select } from 'antd';

//custom components
import DependentsList from './DependentsList';

//helpers
import { allRelations, allPostSecondaryStudent, allDisabilityTaxCredit } from '../../common/consts/ProtaxHelpers';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

//consts
import moment from 'moment';
import { toast } from 'react-toastify';

const { Option } = Select;

function BasicDetails(props) {

    let {

        //basic details
        dependants,
        tempDependantsInfo,
        receiptsChecklist,
        maritalStatus,

        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Dependents Details ({dependants.length})
                        <span 
                            className="p-1 cursor-pointer"
                            data-toggle="modal" 
                            data-target="#dependentList"
                        >
                            <i className="fa fa-eye text-grey" />
                        </span>
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Relation</label>
                        <Select
                            placeholder="Gender"
                            className="text-capitalize w-100"
                            value={tempDependantsInfo.relation}
                            onChange={(value) => {
                                handleRadios('relation', value);
                            }}
                        >
                            {
                                allRelations.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>First Name</label>
                        <input
                            value={tempDependantsInfo.firstName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Legal Name"
                            name="firstName"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Middle Name</label>
                        <input
                            value={tempDependantsInfo.firstName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Legal Name"
                            name="firstName"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Last Name</label>
                        <input
                            value={tempDependantsInfo.middleName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Middle Name"
                            name="middleName"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Net Income</label>
                        <input
                            value={tempDependantsInfo.netIncome}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Net Income"
                            name="netIncome"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                        <label>DOB</label>
                        <DatePicker
                            value={moment(tempDependantsInfo.dob, 'YYYY-MM-DD')}
                            style={{ width: '100%', height: '40px' }}
                            onChange={(value, datestring) => {
                                handleDates('dob', datestring)
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Post-Secondary Student</label>
                        <Select
                            placeholder="Marital Status"
                            className="text-capitalize w-100"
                            value={tempDependantsInfo.postSecondaryStudent}
                            onChange={(value) => {
                                handleRadios('postSecondaryStudent', value);
                            }}
                        >
                            {
                                allPostSecondaryStudent.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Disability Tax Credit</label>
                        <Select
                            placeholder="Marital Status"
                            className="text-capitalize w-100"
                            value={tempDependantsInfo.disabled}
                            onChange={(value) => {
                                handleRadios('disabled', value);
                            }}
                        >
                            {
                                allDisabilityTaxCredit.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-12 text-center">
                    <button
                        type="button"
                        className="btn btn-primary btn-round"
                        onClick={() => {
                            addDependent()
                        }}
                    >
                        <i className="nc-icon nc-simple-add"></i>
                    </button>
                </div>
            </div>
            <DependentsList />
        </Fragment>
    )

    function addDependent() {
        const id = new Date().getTime();
        let newDependants = dependants.slice();
        newDependants.push({
            ...tempDependantsInfo,
            id,
            amount: ''
        });

        handleChange('dependants', newDependants);
        if (tempDependantsInfo.relation === 'children') {
            let newReceipts = receiptsChecklist.slice();
            let rIndex = newReceipts.findIndex(e => e.type === 'child');
            if (rIndex !== -1) {
                newReceipts[rIndex].childs.push({
                    ...tempDependantsInfo,
                    id,
                    amount: ''
                });
                handleChange('receiptsChecklist', newReceipts);
            }
        }
        toast.success('New Dependant Added Successfully!!')
        handleChange('tempDependantsInfo', {
            relation: '',
            firstName: '',
            lastName: '',
            dob: new Date(),
            netIncome: '',
            postSecondaryStudent: '',
            disabled: ''
        })
    }

    function handleRadios(name, value) {
        let newDependeantsInfo = {
            ...tempDependantsInfo,
            [name]: value
        }
        handleChange('tempDependantsInfo', newDependeantsInfo)
    }
    function handleDates(name, value) {
        let newDependeantsInfo = {
            ...tempDependantsInfo,
            [name]: value
        }
        handleChange('tempDependantsInfo', newDependeantsInfo)
    }
    function handleText(e) {
        let { value, name } = e.target;
        let newDependeantsInfo = {
            ...tempDependantsInfo,
            [name]: value
        }
        handleChange('tempDependantsInfo', newDependeantsInfo)
    }
}


const mapStateToProps = () => state => ({
    //basic details
    dependants: state.Pickup.dependants,
    tempDependantsInfo: state.Pickup.tempDependantsInfo,
    receiptsChecklist: state.Pickup.receiptsChecklist,
    maritalStatus: state.Pickup.maritalStatus
});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);