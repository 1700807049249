import React from 'react';
import { Divider } from 'antd';
import NotFound from '../../../assets/images/notFound.png'

export default function QuestionsForm(props) {
    let { questions, title, fillingForSpouse } = props;
    return (
        <>
            <div className="row">
                <div className="col-12 mb-2">
                    <h5>{title}</h5>
                </div>
            </div>
            <div className="row">
                {
                    questions.map((question, index) => {
                        return (
                            <div className="col-12" key={index}>
                                <div className="text-bold fa-lg mb-3">Question {index + 1}</div>
                                {
                                    question.questions.map((qe, qi) => {
                                        return (
                                            <div key={qi}>{qe.question}</div>
                                        )
                                    })
                                }
                                <div className="text-bold">{question.checked ? ("Yes") : 'NO'}</div>

                                {
                                    fillingForSpouse && question?.askSpouse && question?.checked ? (
                                        <>
                                            <div>{question?.questionForSpouse}</div>
                                            <div className="text-bold">{question.spouseChecked ? ("Yes") : 'NO'}</div>
                                        </>
                                    )
                                        : null
                                }
                                <Divider />
                            </div>
                        )
                    })
                }
            </div>
            {
                questions.length === 0 && (
                    (
                        <div className="row">
                            <div className="col-md-12 text-center m-3">
                                <img
                                    src={NotFound}
                                    className="img-fluid"
                                    width="300"
                                />
                            </div>
                            <div className="col-md-12 text-center">
                                <span>No {title} Found!!</span>
                            </div>
                        </div>
                    )
                )
            }
        </>
    )
}