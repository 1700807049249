import React, { Fragment, useEffect, useState } from 'react';

//redux imports
import { connect } from 'react-redux';
import { setEditState, resetPickupState } from '../../redux/actions/PickupActions';

import { toast } from 'react-toastify';
import Helper from '../../common/consts/Helper';

import Spinner from '../../common/components/spinner';

//forms
import BasicDetails from './BasicDetails';
import ContactDetails from './ContactDetails';
import SpouseDetails from './SpouseDetails';
import DependentsDetails from './DependentsDetails';
import QuestionDetails from './QuestionDetails';
import SlipsDetails from './SlipsDetails';
import ReceiptsChecklist from './ReciptsChecklist';
import OtherChecklsit from './OtherChecklsit';
import SpouseReceiptChecklist from './SpouseReceiptChecklist';
import SpouseSlipsDetails from './SpouseSlipsDetails';
import SpouseOtherChecklist from './SpouseOtherChecklist';

function EditOrder(props) {

    let {
        fillingForSpouse,
        setEditState,
        PickupParams
    } = props;

    const order = props.history.location?.state?.order;
    useEffect(() => {
        setEditState({
            ...order,
            isEdit: true
        });
    }, []);

    const [isLoading, setIsLoading] = useState('');

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-user">
                        <div className="card-header">
                            <h5 className="card-title">Edit Order</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submit}>
                                <BasicDetails />
                                <ContactDetails />
                                <SpouseDetails />
                                <DependentsDetails />
                                <QuestionDetails />
                                <SlipsDetails />
                                <ReceiptsChecklist />
                                <OtherChecklsit />
                                {
                                    fillingForSpouse ? (
                                        <Fragment>
                                            <SpouseSlipsDetails />
                                            <SpouseReceiptChecklist />
                                            <SpouseOtherChecklist />
                                        </Fragment>
                                    ) : null
                                }

                                <div className="row">
                                    <div className="update ml-auto mr-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-round"
                                            disabled={isLoading}
                                        >
                                            {
                                                isLoading ? (<Spinner />) : (
                                                    'Edit Order'
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function submit(e) {
        let confirmation = window.confirm("Are you sure you want to Edit the order?");
        if (confirmation) {
            e.preventDefault();
            const url = '/edit_order';
            const method = 'PUT';
            setIsLoading(true);
            let body = {
                ...PickupParams,
                pickupId: PickupParams._id,
                gender: typeof (PickupParams.gender) === 'string' ? (PickupParams.gender === 'male' ? 0 : 1) : (PickupParams.gender),
                preferredContactMethod: typeof (PickupParams.preferredContactMethod) === 'string' ? (PickupParams.preferredContactMethod === 'mobile' ? 0 : 1) : PickupParams.preferredContactMethod,
                preferredReviewMethod: typeof (PickupParams.preferredReviewMethod) === 'string' ? (PickupParams.preferredReviewMethod === 'in person' ? 0 : 1) : PickupParams.preferredReviewMethod,
                maritalStatus: typeof (PickupParams.maritalStatus) === 'string' ? (getMaritalStatus(PickupParams.maritalStatus)) : PickupParams.maritalStatus,
            }
            if (!body.fillingForSpouse) {
                body.spouseInfo = {
                    ...body.spouseInfo,
                    disabled: false,
                    postSecondaryStudent: false
                };
            }
            let res = Helper(url, method, body);
            res.then((response) => {
                if (response.error) {
                    toast.error(response.error?.toString())
                }
                else {
                    toast.success(response.message?.toString())
                    resetPickupState();
                    props.history.goBack()
                }
            })
                .catch((error) => {
                    toast.error(error.toString())
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }
    function getMaritalStatus(maritalStatus) {
        if (maritalStatus === 'single') return 0;
        else if (maritalStatus === 'married') return 1;
        else if (maritalStatus === 'common-law') return 2;
        else if (maritalStatus === 'widowed') return 3;
        else if (maritalStatus === 'seperated') return 4;
        else if (maritalStatus === 'divorced') return 5;
        else return 0;
    }
}


const mapStateToProps = () => state => ({
    //spouse details
    fillingForSpouse: state.Pickup.fillingForSpouse,
    PickupParams: state.Pickup,
});

const mapDispatchToProps = () => dispatch => ({
    setEditState: (state) => dispatch(setEditState(state)),
    resetPickupState: () => dispatch(resetPickupState()),

})


export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);