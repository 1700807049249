//marital status
export function getMaritalApi(maritalStatus) {
    if (maritalStatus === 'single') return 0;
    else if (maritalStatus === 'married') return 1;
    else if (maritalStatus === 'common-law') return 2;
    else if (maritalStatus === 'widowed') return 3;
    else if (maritalStatus === 'seperated') return 4;
    else if (maritalStatus === 'divorced') return 5;
    else return 0;
}

export function getMaritalString(maritalStatus) {
    if (maritalStatus === 0) return 'single';
    else if (maritalStatus === 1) return 'married';
    else if (maritalStatus === 2) return 'common-law';
    else if (maritalStatus === 3) return 'widowed';
    else if (maritalStatus === 4) return 'seperated';
    else if (maritalStatus === 5) return 'divorced';
    else return 'Error';
}

//gender
export function getGenderApi(gender) {
    if (gender === 'male') return 0;
    else if (gender === 'female') return 1;
}

export function getGenderString(gender) {
    if (gender === 0) return 'male';
    else if (gender === 1) return 'female';
}


//preffered contact method
export function getPreferredContactMethodApi(preferredContactMethod) {
    if (preferredContactMethod === 'mobile') return 0;
    else if (preferredContactMethod === 'email') return 1;
}

export function getPreferredContactMethodString(preferredContactMethod) {
    if (preferredContactMethod === 0) return 'mobile';
    else if (preferredContactMethod === 1) return 'email';
}

//preffered review method
export function getPreferredReviewMethodApi(preferredReviewMethod) {
    if (preferredReviewMethod === 'in person') return 0;
    else if (preferredReviewMethod === 'by phone') return 1;
}

export function getPreferredReviewMethodString(preferredReviewMethod) {
    if (preferredReviewMethod === 0) return 'in person';
    else if (preferredReviewMethod === 1) return 'by phone';
}

//status
export const StatusString = {
    1: 'pending',
    2: 'work in progress',
    3: 'completed',
    4: 'file e filed',
}

export const StatusApi = {
    'pending': 1,
    'work in progress': 2,
    'completed': 3,
    'file e filed': 4,
}

//amount calcutation
export function totalAmount(baseOrder, discount) {
    if (baseOrder && Array.isArray(baseOrder)) {
        let total = - +(discount || 0);
        for (const order of baseOrder) {
            total += +order.amount;
        }
        return total.toFixed(2);
    }
}

//gender
export const allGenders = [{
        name: 'Male',
        value: 0
    },
    {
        name: 'Female',
        value: 1
    }
]

//preferred contact method
export const allPreferredContactMethods = [{
        name: 'Mobile',
        value: 0
    },
    {
        name: 'Email',
        value: 1
    }
]

//preferred review method
export const allPreferredReviewMethods = [{
        name: 'In Person',
        value: 0
    },
    {
        name: 'By Phone',
        value: 1
    }
]

//marital status
export const allMaritalStatus = [{
        name: 'single',
        value: 0
    },
    {
        name: 'married',
        value: 1
    },
    {
        name: 'divorced',
        value: 5
    },
    {
        name: 'seperated',
        value: 4
    },
    {
        name: 'widowed',
        value: 3
    },
    {
        name: 'common-law',
        value: 2
    }
]

export const allFillingForSpouse = [{
        name: 'yes',
        value: true
    },
    {
        name: 'no',
        value: false
    },
]

export const allPostSecondaryStudent = [{
        name: 'yes',
        value: true
    },
    {
        name: 'no',
        value: false
    },
]


export const allDisabilityTaxCredit = [{
        name: 'yes',
        value: true
    },
    {
        name: 'no',
        value: false
    },
]

export const allRelations = [{
        name: 'Children',
        value: 'children'
    },
    {
        name: 'Parents',
        value: 'parents'
    },
    {
        name: 'Grand Parents',
        value: 'grand parents'
    },
    {
        name: 'Niece',
        value: 'niece'
    },
    {
        name: 'Nephew',
        value: 'nephew'
    },
    {
        name: 'Grand Children',
        value: 'grand children'
    }
]

export const collegeDetails = [
    {
        collegeName: 'Mohawk College',
        discount: '25',
        collegeImageUrl: 'https://www.mohawkcollege.ca/themes/de_theme/img/subsite-logos/mohawk_subsite.png'
    }
]

export const userTypes = [
    {
        userType: 2,
        title: 'User'
    },
    {
        userType: 4,
        title: 'Student'
    }
]