import React, { useState, useRef, useEffect } from "react";
import { BASE_URL } from "../../common/consts/Config";
import Helper from "../../common/consts/Helper";
import { toast } from "react-toastify";
import { Typography } from "antd";

export default function FinalDocModal({
  orderId,
  fetchOrders,
  finalDoc,
  spouseDetails,
}) {
  const { Title } = Typography;
  const closeRef = useRef();
  const [document, setDocument] = useState(null);
  const [spouseDocument, setSpouseDocument] = useState(null);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTitle(finalDoc?.title || "");
    setDocument({ name: finalDoc?.document?.replace('/images/', '') || "Choose File" });
    setSpouseDocument({ name: finalDoc?.spouseDocument?.replace('/images/', '') || "Choose File" });
  }, [finalDoc]);

  return (
    <div
      className="modal fade"
      id="uploadFinalDoc"
      tabindex="-1"
      role="dialog"
      aria-labelledby="uploadFinalDocLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <form onSubmit={submit}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="uploadFinalDocLabel">
                Upload Client’s Copy
              </h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h6 className="text-bold">File - {finalDoc?.fullName}</h6>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      className="form-control text-capitalize"
                      placeholder="Title"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      name="title"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <Title level={5}>Upload Document</Title>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="text-uppercase">
                      Document for {finalDoc?.fullName}
                    </div>
                    <div className="input-group mb-3">
                      <div className="custom-file">
                        <input
                          required
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile01"
                          accept="application/pdf"
                          onChange={handleUserImage}
                        />
                        <label
                          className="custom-file-label"
                          for="inputGroupFile01"
                        >
                          {document?.name || "Choose File"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {spouseDetails?.fillingForSpouse && (
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="text-uppercase">
                        Document for {spouseDetails?.spouseInfo?.firstName}
                      </div>
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            required
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile02"
                            accept="application/pdf"
                            onChange={handleSpouseImage}
                          />
                          <label
                            className="custom-file-label"
                            for="inputGroupFile01"
                          >
                            {spouseDocument?.name || "Choose File"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                ref={closeRef}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? "Wait..." : "Save changes"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
  function submit(e) {
    e.preventDefault();
    setIsLoading(true);
    let body = new FormData();
    body.append("orderId", orderId);
    body.append("title", title);
    body.append("document", document);
    body.append("spouseDocument", spouseDocument);

    let res = Helper("/add_order_document", "POST", body, true);
    res
      .then((response) => {
        if (response?.error) {
          toast.error(response.error);
        } else {
          fetchOrders();
          toast.success(response?.message);
          closeRef.current.click();
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
        setDocument(null);
        setSpouseDocument(null);
      });
  }

  function handleUserImage(e) {
    let { files } = e?.target;
    if (files.length > 0) {
      setDocument(files[0]);
    }
  }

  function handleSpouseImage(e) {
    let { files } = e?.target;
    if (files.length > 0) {
      setSpouseDocument(files[0]);
    }
  }

  // function getImageSrc() {
  //   let url = "";
  //   if (typeof document === "string") url = BASE_URL + document;
  //   else if (typeof document === "object") url = URL.createObjectURL(document);
  //   return url;
  // }
}
