import React, { useEffect, useState } from 'react';
import { Pagination, Checkbox } from 'antd';
import Spinner from '../../common/components/spinner';

//consts
import { BASE_URL } from '../../common/consts/Config';
import { toast } from 'react-toastify';
import Helper from '../../common/consts/Helper';

//images
import UserProfileImage from '../../assets/images/avtar.png';
import NotFound from '../../assets/images/notFound.png';
import { Link } from 'react-router-dom';
import UserTypeModal from './UserTypeModal';

export default function User(props) {

    const { history } = props;

    const [users, setUsers] = useState([]);
    const [currentUserType, setCurrentUserType] = useState(2);
    const [paginationParams, setPaginationParams] = useState({
        limit: 10,
        skip: 0,
        current: 1
    });
    const [isLoading, setIsLoading] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        fetchUser(currentUserType);
    }, [currentUserType, paginationParams]);

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title"> Members</h4>
                            <div className="update">
                                <Link to="/add_student">
                                    <button type="button" className="btn btn-primary btn-round">Add Student</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-header">
                            <form>
                                <div className="input-group no-border">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="nc-icon nc-zoom-split"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Checkbox onChange={(e) => {
                                setPaginationParams({
                                    ...paginationParams,
                                    limit: 10,
                                    skip: 0,
                                    current: 1
                                })
                                if (e.target.checked)
                                    setCurrentUserType(4)
                                else
                                    setCurrentUserType(2)

                            }}>
                                Show Students
                            </Checkbox>
                        </div>
                        <div className="card-body">

                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                                </th>
                                        <th className="text-center">
                                            Image
                                                </th>
                                        <th className="text-center">
                                            Name
                                                </th>
                                        <th className="text-center">
                                            Email
                                                </th>
                                        <th className="text-center">
                                            Mobile
                                                </th>
                                        <th className="text-center">
                                            Change Type
                                                </th>
                                        {currentUserType === 4 ? (
                                            <th className="text-center">
                                                Edit
                                            </th>) : null
                                        }
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && (
                                                <tr className="col-12 text-center">
                                                    <td colSpan="6">
                                                        <Spinner />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            users.map((user, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-center">
                                                            <img
                                                                src={user.profileImage ? (BASE_URL + user.profileImage) : (UserProfileImage)}
                                                                className="img-fluid"
                                                                width="150"
                                                            />
                                                        </td>
                                                        <td className="text-center text-capitalize">
                                                            {user.name}
                                                        </td>
                                                        <td className="text-center">
                                                            {user.email}
                                                        </td>
                                                        <td className="text-center">
                                                            {user.mobile}
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                onClick={() => {
                                                                    setCurrentUser(user)
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#userTypeModal"
                                                                type="button"
                                                                className="btn btn-primary btn-round"
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                currentUserType === 4 ? (
                                                                    <button
                                                                        onClick={() => {
                                                                            history.push('/add_student', {
                                                                                student: user,
                                                                                edit: true
                                                                            })
                                                                        }}
                                                                        type="button"
                                                                        className="btn btn-primary btn-round bg-warning"
                                                                    >
                                                                        <i className="fas fa-edit"></i>
                                                                    </button>
                                                                ) : null
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {
                                    users.length !== 0 && (
                                        <Pagination
                                            showSizeChanger
                                            onChange={(current, limit) => {
                                                if (limit !== paginationParams.limit) {
                                                    setPaginationParams({
                                                        ...paginationParams,
                                                        limit: limit,
                                                        skip: 0,
                                                        current: 1
                                                    });
                                                }
                                                else {
                                                    setPaginationParams({
                                                        ...paginationParams,
                                                        skip: ((current - 1) * paginationParams.limit),
                                                        current
                                                    });
                                                }
                                            }}
                                            current={paginationParams.current}
                                            defaultCurrent={1}
                                            total={totalUsers}
                                        />
                                    )
                                }
                            </div>
                            {
                                !isLoading && users.length === 0 && (
                                    (
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <img
                                                    src={NotFound}
                                                    className="img-fluid"
                                                    width="300"
                                                />
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <span>No Students members Found!!</span>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <UserTypeModal
                currentUser={currentUser}
                fetchUser={fetchUser}
                fetchThisUserType={currentUserType}
            />
        </div>
    )
    function fetchUser(userType) {
        window.scrollTo(0, 0);
        setIsLoading(true);
        let body = {
            limit: paginationParams.limit,
            skip: paginationParams.skip,
            userType
        }
        let res = Helper('/users', 'POST', body);
        res.then((response) => {
            if (response.error) toast.error(response.error)
            else {
                setUsers(response.users);
                setTotalUsers(response.totalUsers);
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false));
    }
}