import React from 'react';
import { Divider } from 'antd';

export default function PaymentMode(props) {
    let { baseOrder, title, discount } = props;

    let total = 0;

    for (const el of baseOrder) {
        total += +el.amount;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h5>{title}</h5>
                </div>
            </div>
            {
                baseOrder.filter(e => !e.spouse).length !== 0 ? (
                    <div className="row">
                        <div className="col-12 text-bold">Your Charges</div>
                    </div>
                ) : null
            }
            {
                baseOrder.filter(e => !e.spouse).map((order, index) => {
                    return (
                        <div
                            className="row  mt-2"
                            key={index}
                        >
                            <div
                                className="col-6 text-left"
                            >
                                <div className="text-capitalize">{order.title}</div>
                                <div className="text-secondary">{order.description} {+order.numberOfItems > 1 ? (`(${order.numberOfItems} x $${order.charge})`) : ''}</div>
                            </div>
                            <div className="col-6 text-right">
                                <span className="text-bold">${order.amount}</span>
                            </div>
                        </div>
                    )
                })
            }
            {
                baseOrder.filter(e => e.spouse).length !== 0 ? (
                    <div className="col-12 text-bold mt-3 pl-0">Spouse Charges</div>
                ) : null
            }
            {
                baseOrder.filter(e => e.spouse).map((order, index) => {
                    return (
                        <div
                            className="row  mt-2"
                            key={index}
                        >
                            <div
                                className="col-6 text-left"
                            >
                                <div className="text-capitalize">{order.title}</div>
                                <div className="text-secondary">{order.description} {+order.numberOfItems > 1 ? (`(${order.numberOfItems} x $${order.charge})`) : ''}</div>
                            </div>
                            <div className="col-6 text-right">
                                <span className="text-bold">${order.amount}</span>
                            </div>
                        </div>
                    )
                })
            }
            <Divider />
            {
                discount && discount > 0 ? (
                    <div className="row mt-4 mb-4">
                        <div className="col-6 text-left fa-lg">Discount</div>
                        <div className="col-6 text-bold text-right fa-lg">${discount}</div>
                    </div>
                ) : null
            }
            <div className="row mt-4 mb-4">
                <div className="col-6 text-left fa-lg">Total</div>
                <div className="col-6 text-bold text-right fa-lg">${(total - +discount).toFixed(2)}</div>
            </div>
        </>
    )
}