import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

//constants
import Helper from '../../common/consts/Helper';
import { toast } from 'react-toastify';

//custom components
import OrderChart from './OrdersChart';
import SalesChart from './SalesChart';

export default function Dashboard(props) {

    const [stats, setStats] = useState({
        totalMembers: 0,
        totalOrders: 0,
        totalUsers: 0,
        webOrders: 0,
        iosOrders: 0,
        totalStudents: 0
    });

    useEffect(() => {
        fetchStats();
    }, []);

    let { totalMembers, totalOrders, totalUsers, webOrders, iosOrders, totalStudents } = stats;

    return (
        <div className="content">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-globe text-warning"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Total Users</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={totalUsers}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-money-coins text-success"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Total Orders</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={totalOrders}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-vector text-danger"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Total Staff</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={totalMembers}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fas fa-desktop text-danger"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Web Orders</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={webOrders}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fab fa-android text-success"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Android Orders</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={totalOrders - (webOrders + iosOrders)}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fas fa-mobile-alt text-dark"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">iOS Orders</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={iosOrders}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card card-stats">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-5 col-md-4">
                                    <div className="icon-big text-center icon-warning">
                                        <i className="fas fa-graduation-cap text-dark"></i>
                                    </div>
                                </div>
                                <div className="col-7 col-md-8">
                                    <div className="numbers">
                                        <p className="card-category">Students</p>
                                        <p className="card-title">
                                            <CountUp
                                                start={0}
                                                end={totalStudents}
                                                duration={4.75}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-clock-o"></i>
                                Updated Just Now
                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="card-header ">
                            <h5 className="card-title">Orders - Chart</h5>
                            <p className="card-category">According to Date Range</p>
                        </div>
                        <div className="card-body ">
                            <OrderChart />
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-history"></i> Updated 3 minutes ago
                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card ">
                        <div className="card-header ">
                            <h5 className="card-title">Sales Statistics</h5>
                            <p className="card-category">Current Performance</p>
                        </div>
                        <div className="card-body ">
                            <SalesChart />
                        </div>
                        <div className="card-footer ">
                            <hr />
                            <div className="stats">
                                <i className="fa fa-calendar"></i> Sales Done in Date Range
                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    function fetchStats() {
        let res = Helper('/dashboard', 'GET');
        res.then((response) => {
            if (response.error) toast.error(response.error);
            else {
                setStats({
                    ...stats,
                    ...response
                })
            }
        })
            .catch((error) => null)
    }
}