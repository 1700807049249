import React, { useState, useEffect, useRef } from 'react';

//routing
import { useParams } from 'react-router-dom';

//constants
import { toast } from 'react-toastify';
import Spinner from '../../common/components/spinner';
import { BASE_URL } from '../../common/consts/Config';
import Helper from '../../common/consts/Helper';
// import Pdf from "react-to-pdf";
// import { PDFImage } from 'pdf-image';

// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

import { connect } from 'react-redux';

function DocumentUpload(props) {

    const documentRef1 = useRef();
    const documentRef2 = useRef();
    const documentRef3 = useRef();
    const documentRef4 = useRef();
    const documentRef5 = useRef();


    const { orderId } = useParams();

    const [data, setData] = useState({
        document1: null,
        signature1: null,

        document2: null,
        signature2: null,

        document3: null,
        signature3: null,

        document4: null,
        signature4: null,

        document5: null,
        signature5: null,

        fullName: '',
    });
    const [isLoading, setIsLoading] = useState({
        loading: false,
        index: null
    });

    useEffect(() => {
        let { state } = props.history.location;
        if (state) {
            setData({
                ...data,
                document1: state?.document1,
                document2: state?.document2,
                document3: state?.document3,
                document4: state?.document4,
                document5: state?.document5,

                signature1: state?.signature1,
                signature2: state?.signature2,
                signature3: state?.signature3,
                signature4: state?.signature4,
                signature5: state?.signature5,

                fullName: state?.fullName,
            })
        }
    }, []);

    const { document1, document2, document3, document4, document5, signature1, signature2, signature3, signature4, signature5 } = data;

    let {
        admin_signature
    } = props;

    console.log(admin_signature)

    function printDiv(id) {
        var divContents = document.getElementById(id).innerHTML;
        var a = window.open('', '', 'height=1000, width=1000');
        a.document.write('<html>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        setTimeout(function () { a.print(); }, 500);
    }

    // function printDocument(id) {
    //     const input = document.getElementById(id);
    //     html2canvas(input, {
    //         scrollX: 0,
    //         scrollY: -window.scrollY,
    //         logging: true,
    //         useCORS: true,
    //     })
    //         .then((canvas) => {
    //             const imgData = canvas.toDataURL('image/jpeg');
    //             console.log(imgData);
    //             const pdf = new jsPDF();
    //             pdf.addImage(imgData, 'JPEG', 0, 0);
    //             // pdf.output('dataurlnewwindow');
    //             pdf.save("document.pdf");
    //         })
    //         ;
    // }

    // function convertPdfToImage(e) {
    // console.log(URL.createObjectURL(e.target.files[0]))
    // var pdfImage = new PDFImage(URL.createObjectURL(e.target.files[0]));
    // pdfImage.convertPage(0).then(function (imagePath) {
    //     console.log(imagePath)
    //     // 0-th page (first page) of the slide.pdf is available as slide-0.png
    //     // fs.existsSync("/tmp/slide-0.png") // => true
    //   })
    //   .catch((error)=>{
    //       console.log(error)
    //   })

    // }

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12" >
                    <div className="card card-user">
                        <div className="card-header">
                            <h5 className="card-title">Add Document</h5>
                            <h5 className="card-title">File - {data.fullName}</h5>
                        </div>
                        <div className="card-body">
                            {renderForm(document1, signature1, 1, documentRef1)}
                            {renderForm(document2, signature2, 2, documentRef2)}
                            {renderForm(document3, signature3, 3, documentRef3)}
                            {renderForm(document4, signature4, 4, documentRef4)}
                            {renderForm(document5, signature5, 5, documentRef5)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    function renderForm(document, signature, docNumber, refrence) {
        return (
            <form onSubmit={(e) => { submit(e, docNumber) }}>
                <div className="row" >
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Upload Document {docNumber} - <b>{docNumber % 2 == 0 ? 'Authorization Form' : '183 form'}</b></label>
                            <div className="input-group mb-3">
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        onChange={(e) => {
                                            // convertPdfToImage(e)
                                            handleImage(e, docNumber)
                                        }}
                                        required
                                    />
                                    <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        document ? (
                            <div className="col-md-12" >
                                <div className="form-group" style={{ flexDirection: 'column', display: 'flex' }}>
                                    <div className="d-flex align-items-center">
                                        <label>
                                            Document
                                    </label>
                                        <button
                                            type="button"
                                            className="btn btn-primary bg-success ml-2"
                                            onClick={() => { printDiv(`printthis${docNumber}`) }}
                                        >
                                            <i class="fas fa-download"></i>
                                        </button>
                                    </div>
                                    <div id={`printthis${docNumber}`}>
                                        <div className="position-relative doc-parent" style={{
                                            position: "relative",
                                        }}>
                                            <div>
                                                <img
                                                    src={getImageSrc(`document${docNumber}`)}
                                                    className="img-fluid doc-image-current"
                                                    style={{ width: '800px', borderRadius: 10 }}
                                                />
                                                {
                                                    document && (
                                                        signature ? (
                                                            renderSignature(docNumber)
                                                        ) : null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="col-md-12">
                        {
                            document && (
                                signature ? (
                                    <div className="col-md-12">
                                        <div className="form-group" style={{ flexDirection: 'column', display: 'flex' }}>
                                            <label>User - Signature</label>
                                            <img
                                                src={getImageSrc(`signature${docNumber}`)}
                                                className="img-fluid"
                                                style={{ width: '250px', borderRadius: 10 }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                        <h5>No Signature added yet!!</h5>
                                    )
                            )
                        }
                    </div>
                    <div className="col-md-12 text-right">
                        <button
                            type="submit"
                            className="btn btn-info"
                            disabled={isLoading.loading && isLoading.index === docNumber}
                        >
                            {isLoading.loading && isLoading.index === docNumber && <Spinner />} Add Document {docNumber}
                        </button>
                    </div>
                </div>
            </form>
        )
    }
    function renderSignature(docNumber) {
        let sigStyles = {
            bottom: admin_signature?.y1 ? `${admin_signature?.y1}px` : '340px',
            height: admin_signature?.height ? `${admin_signature?.height}px` : '23px',
            width: admin_signature?.width ? `${admin_signature?.width}px` : '100px',
            left: admin_signature?.x1 ? `${admin_signature?.x1}px` : '84px',
            background: admin_signature?.background || '#fff',
            position: 'absolute'
        }
        let sigClasses = `document-signature`;
        if (docNumber % 2 == 0) {
            sigStyles.bottom = admin_signature?.y2 ? `${admin_signature?.y2}px` : '430px';
            sigStyles.left = admin_signature?.x2 ? `${admin_signature?.x2}px` : '160px';
            sigStyles.background = 'transparent';
            sigClasses = `document-signature-2`;
        }
        return (
            <div className={`position-absolute ${sigClasses}`}
                style={sigStyles}
            >
                <img
                    src={getImageSrc(`signature${docNumber}`)}
                    className="img-fluid position-absolute"
                    style={{
                        position: 'absolute',
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </div>
        )
    }
    function submit(e, docNumber) {
        e.preventDefault();
        setIsLoading({
            ...isLoading,
            index: docNumber,
            loading: true
        });
        let body = new FormData();
        body.append('image', data[`document${docNumber}`]);
        body.append('orderId', orderId);
        body.append('docNo', docNumber)  //1-5
        let res = Helper('/upload_document', 'POST', body, true);
        res.then((response) => {
            if (response.error) {
                toast.error(response.error.toString())
            }
            else {
                toast.success(response.message);
            }
        })
            .catch((error) => null)
            .finally(() => {
                setIsLoading({
                    ...isLoading,
                    index: null,
                    loading: false
                });
            })
    }
    function getImageSrc(myvar) {
        let image = data[myvar];
        let url = '';
        if (typeof (image) === 'string')
            url = BASE_URL + image
        else if (typeof (image) === 'object')
            url = URL.createObjectURL(image);
        return url
    }
    function handleImage(e, docNumber) {
        let { files } = e.target;
        if (files.length > 0) {
            setData({
                ...data,
                [`document${docNumber}`]: files[0]
            });
        }
    }
}

const mapStateToProps = () => state => ({
    admin_signature: state.Pickup.admin_signature
})

export default connect(mapStateToProps)(DocumentUpload);