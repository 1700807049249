import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//toast contaienr and css
import { ToastContainer } from 'react-toastify';

//css required
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';

//screens
import Main from './components/main';
import Login from './components/login';

//redux imports
import { Provider } from 'react-redux';
import store from '../src/redux/store';

function App() {
  return (
    <Provider
      store={store}
    >
      <div>
        <Router>
          <Switch>
            <Route path="/" render={
              (props) =>
                localStorage.getItem('access_token')
                  ? <Main {...props} />
                  : <Login {...props} />
            }
            />
          </Switch>
        </Router>
        <ToastContainer />
      </div >
    </Provider>
  );
}

export default App;
