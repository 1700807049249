import { Select } from "antd";
import { useState, useRef } from "react"
import { toast } from "react-toastify";
import Helper from "../../common/consts/Helper";
import { userTypes } from '../../common/consts/ProtaxHelpers';

const { Option } = Select;

export default function UserTypeModal({ currentUser, fetchUser, fetchThisUserType }) {

    const [currentUserType, setCurrentUserType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const closeRef = useRef();

    return (
        <div class="modal fade" id="userTypeModal" tabindex="-1" aria-labelledby="userTypeModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="userTypeModalLabel">Change User Type</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Select User Type</label>
                                    <div>
                                        <Select
                                            value={currentUserType}
                                            style={{ width: '100%' }}
                                            onChange={(value, record) => {
                                                setCurrentUserType(value)
                                            }}
                                        >
                                            {
                                                userTypes.map((userType, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={userType.userType}
                                                        >
                                                            {userType.title}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            ref={closeRef}
                            type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            onClick={saveChanges}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Wait..' : 'Save changes'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
    function saveChanges() {
        if (currentUserType) {
            let confirmation = window.confirm("Are you sure you want to change user type?");
            if (confirmation) {
                setIsLoading(true);
                let res = Helper('/change_user_type', 'POST', {
                    userId: currentUser?._id,
                    userType: currentUserType
                });
                res.then((response) => {
                    if (response?.error) {
                        toast.error("Something went wrong, Please try again later!!")
                    }
                    else {
                        toast.success(response?.message);
                        fetchUser(fetchThisUserType);
                        closeRef.current.click();
                    }
                })
                    .catch((error) => { })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    }
}