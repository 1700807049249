export default {
    preferredContactMethod: [{
            title: 'mobile',
            value: 0,
            checked: false
        },
        {
            title: 'email',
            value: 1,
            checked: false,
        }
    ],
    preferredReviewMethod: [{
            title: 'in person',
            value: 0,
            checked: false,
        },
        {
            title: 'by phone',
            value: 1,
            checked: false,
        }
    ],
    gender: [{
            title: 'male',
            value: 0,
            checked: false,
        },
        {
            title: 'female',
            value: 1,
            checked: false,
        }
    ],
    maritalStatus: [{
            title: 'single',
            value: 0,
            checked: false,
        },
        {
            title: 'married',
            value: 1,
            checked: false,
        },
        {
            title: 'common-law',
            value: 2,
            checked: false,
        },
        {
            title: 'widowed',
            value: 3,
            checked: false,
        },
        {
            title: 'seperated',
            value: 4,
            checked: false,
        },
        {
            title: 'divorced',
            value: 5,
            checked: false,
        },
    ],
    checklist: [
        //slips
        {
            title: 'T4 slips (Employment income)',
            checked: false
        },
        {
            title: 'T4RIF slips',
            checked: false,
        },
        {
            title: 'T4RSP slips',
            checked: false,
        },
        {
            title: 'T4PS slips',
            checked: false,
        },
        {
            title: 'T4RCA slips',
            checked: false,
        },
        {
            title: 'T5013 slips',
            checked: false,
        },
        {
            title: 'Employment insurance benefits (T4E)',
            checked: false
        },
        {
            title: 'Interest, dividends, mutual funds (T3, T5, T5008)',
            checked: false
        },
        {
            title: 'Old Age Security and CPP benefits (T4A-OAS,T4AP)',
            checked: false
        },
        {
            title: 'Other pensions and annuities (T4A)',
            checked: false
        },
        {
            title: 'Support Payments Received',
            checked: false
        },
        {
            title: 'Social assisance payments (T5007)',
            checked: false
        },
        {
            title: 'All other information slips',
            checked: false
        },

        //recipts
        {
            title: 'RRSP contribution receipts',
            checked: false
        },
        {
            title: 'Support for a child, spouse or common-law partner',
            checked: false
        },
        {
            title: 'Profesional or union dues',
            checked: false
        },
        {
            title: 'Tool expenses (Tradespersons & apprentice mechanics)',
            checked: false
        },
        {
            title: 'Other employment expenses (T2200)',
            checked: false
        },
        {
            title: 'Teacher\'s school supplies',
            checked: false
        },
        {
            title: 'Medical expenses',
            checked: false
        },
        {
            title: 'Charitable donations',
            checked: false
        },
        {
            title: 'T2202 Tution and Enrollment Certificate',
            checked: false
        },
        {
            title: 'Political contributions',
            checked: false
        },
        {
            title: 'Child Care Expenses',
            checked: false
        },
        //other checklist
        {
            title: 'Notice of Assessment/ Reassessment',
            checked: false
        },
        {
            title: 'Canada Revenue Agency correspondence',
            checked: false
        },
        {
            title: 'Sale of principal residence',
            checked: false
        },
        {
            title: 'Sale or deemed sale of stocks, bonds or real estate',
            checked: false
        },
        {
            title: 'Rental income and expense receipts',
            checked: false
        },
        {
            title: 'Business, farm or fishing income/expenses',
            checked: false
        },
        {
            title: 'Automobile / Travel logbook and expenses',
            checked: false
        }
    ],
    dateFilter: {
        min: '',
        max: ''
    }
}

export const sortBy = [{
        name: 'Date',
        value: 'createdAt',
    },
    {
        name: 'Name',
        value: 'firstName',
    }
]

export const sortOrder = [{
        name: 'Descending',
        value: -1
    },
    {
        name: 'Ascending',
        value: 1
    }
]