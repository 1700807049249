import React, { useEffect, useState } from "react";

import { Switch, Route } from "react-router-dom";

//screens
import Dashboard from "../dashboard";
import StaffDashboard from "../dashboard/Staff";

//staff
import Staff from "../staff";
import AddStaff from "../staff/createStaff";

//order
import Order from "../order";
import ViewOrder from "../viewOrder";
import StaffOrder from "../order/Staff";
import EditOrder from "../editOrder";

//user
import User from "../user";
import AddStudent from "../addStudent";

//slot
import Slot from "../slot";

//notification
import Notification from "../notification";
import ViewNotification from "../notification/ViewNotfication";

//document
import DocumentUpload from "../documentUpload";
import DocumentUploadDocusign from "../documentUpload/DocumentUploadDocusign";

//common
import SideBar from "../../common/components/sidebar";
import Navbar from "../../common/components/navbar";
import Footer from "../../common/components/footer";

//redux imports
import { connect } from "react-redux";
import { handleMultipleChange } from "../../redux/actions/PickupActions";
import Helper from "../../common/consts/Helper";

function Main(props) {
  const { handleMultipleChange } = props;

  const [isFetchingJson, setIsFetchingJson] = useState(false);

  useEffect(() => {
    fetchJson();
  }, []);

  function fetchJson() {
    setIsFetchingJson(true);
    let res = Helper("/read_json", "GET");
    res
      .then((response) => {
        if (response?.data) handleMultipleChange(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsFetchingJson(false);
      });
  }

  return (
    <div className="wrapper">
      <SideBar />
      <div className="main-panel">
        <Navbar {...props} />
        {+localStorage.getItem("role") === 1
          ? renderAdminRoutes()
          : renderStaffRoutes()}
        <Footer />
      </div>
    </div>
  );
  function renderAdminRoutes() {
    return (
      <Switch>
        <Route path="/document_upload/:orderId" component={DocumentUpload} />
        <Route
          path="/document_upload_web/:orderId"
          component={DocumentUploadDocusign}
        />
        <Route path="/notification_view" component={ViewNotification} />
        <Route path="/notification" component={Notification} />
        <Route path="/edit_order" component={EditOrder} />
        <Route exact path="/staff" component={Staff} />
        <Route path="/staff/order/:id" component={StaffOrder} />
        <Route path="/order_view" component={ViewOrder} />
        <Route path="/slot" component={Slot} />
        <Route path="/add_staff" component={AddStaff} />
        <Route path="/add_student" component={AddStudent} />
        <Route path="/edit_staff" component={AddStaff} />
        <Route path="/order" component={Order} />
        <Route path="/user" component={User} />
        <Route path="/" component={Dashboard} />
      </Switch>
    );
  }
  function renderStaffRoutes() {
    return (
      <Switch>
        <Route path="/document_upload/:orderId" component={DocumentUpload} />
        <Route
          path="/document_upload_web/:orderId"
          component={DocumentUploadDocusign}
        />
        <Route path="/notification" component={Notification} />
        <Route path="/notification_view" component={ViewNotification} />
        <Route path="/order_view" component={ViewOrder} />
        <Route path="/staff/order/:id" component={StaffOrder} />
        <Route path="/" component={StaffDashboard} />
      </Switch>
    );
  }
}

const mapDispatchToProps = () => (dispatch) => ({
  handleMultipleChange: (state) => dispatch(handleMultipleChange(state)),
});

export default connect(null, mapDispatchToProps)(Main);
