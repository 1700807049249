import React, { Fragment } from 'react';

//ant d components
import { Select } from 'antd';

//helpers
import { allPreferredContactMethods, allPreferredReviewMethods } from '../../common/consts/ProtaxHelpers';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';


const { Option } = Select;

function ContactDetails(props) {

    let {

        //basic details
        prefferedContactMobile,
        prefferedContactEmail,
        prefferedContactMethod,
        bestTimeToCall,
        alternateMobileNumber,
        prefferedReviewMethod,

        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Contact Details</span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Preferred Contact Method</label>
                        <Select
                            placeholder="Preferred Contact Method"
                            className="text-capitalize w-100"
                            value={prefferedContactMethod}
                            onChange={(value) => {
                                handleRadios('preferredContactMethod', value);
                            }}
                        >
                            {
                                allPreferredContactMethods.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Preferred Contact Email/Mobile</label>
                        <input
                            value={prefferedContactMethod===0?prefferedContactMobile:prefferedContactEmail}
                            type="text"
                            className="form-control"
                            placeholder="Preferred Contact Details"
                            required
                            name={prefferedContactMethod===0?'preferredContactMobile':'preferredContactEmail'}
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Alternate Mobile Number</label>
                        <input
                            value={alternateMobileNumber}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Preferred Contact Details"
                            name={'alternateMobileNumber'}
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Preferred Method To Review Tax Return</label>
                        <Select
                            placeholder="Preferred Method To Review Tax Return"
                            className="text-capitalize w-100"
                            value={prefferedReviewMethod}
                            onChange={(value) => {
                                handleRadios('preferredReviewMethod', value);
                            }}
                        >
                            {
                                allPreferredReviewMethods.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    function handleRadios(name, value) {
        handleChange(name, value);
    }
    function handleText(e) {
        let { value, name } = e.target;
        handleChange(name, value);
    }
}


const mapStateToProps = () => state => ({

    //contact details
    prefferedContactMobile: state.Pickup.preferredContactMobile,
    prefferedContactEmail: state.Pickup.preferredContactEmail,
    prefferedContactMethod: state.Pickup.preferredContactMethod,
    bestTimeToCall: state.Pickup.bestTimeToCall,
    alternateMobileNumber: state.Pickup.alternateMobileNumber,
    prefferedReviewMethod: state.Pickup.preferredReviewMethod,


});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);