import React, { useState, useEffect, Fragment } from 'react';
import { Chart, Interval, Tooltip } from 'bizcharts';
import { DatePicker } from 'antd';

//custom components
import Spinner from '../../common/components/spinner';

//consts
import moment from 'moment';
import Helper from '../../common/consts/Helper';
import { toast } from 'react-toastify';

const { RangePicker } = DatePicker;

export default function SalesChart() {

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [sales, setSales] = useState(false);
  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    fetchSales();
  }, [dateRange]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12 mb-3">
          <span className="mr-3">Date Range:</span>
          <RangePicker
            onChange={(value) => {
              let startDate = '';
              let endDate = '';
              if (value.length === 2) {
                startDate = value[0];
                endDate = value[1]
              }
              setDateRange({
                ...dateRange,
                startDate,
                endDate
              })
            }}
            disabled={isLoading}
            value={[dateRange.startDate, dateRange.endDate]}
          />
          {
            isLoading && <span className="ml-3"><Spinner /></span>
          }
        </div>
        <div className="col-md-12">
          Amount - ${totalAmount}
        </div>
      </div>
      <Chart height={300} autoFit data={sales} >
        <Tooltip visible={true} />
        <Interval position="date*amount" />
      </Chart>
    </Fragment>
  )

  function fetchSales() {
    setIsLoading(true);
    let res = Helper('/fetch_amount', 'POST', {
      ...dateRange,
      startDate: moment(dateRange?.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateRange?.endDate).format('YYYY-MM-DD'),
  });
    res.then((response) => {
      // console.log(response)
      if (response.error) toast.error(response.error);
      else {
        let newAmounts = []
        for (const order of response.amounts) {
          newAmounts.push({
            date: moment(order._id).format('Do MMMM, YYYY'),
            amount: order.totalAmount
          })
        }
        setSales(newAmounts)
        setTotalAmount(response?.totalAmount || '-')
      }
    })
      .catch((error) => null)
      .finally(() => setIsLoading(false))
  }
}