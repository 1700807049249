import React, { useEffect, useState, useCallback, useRef } from "react";
import { Pagination, Checkbox, Select } from "antd";
import { Tabs } from "antd";

//custom components
import AssignOrder from "../../common/components/assignOrder";
import Filter from "../../common/components/filters";
import Spinner from "../../common/components/spinner";
import FinalDocModal from "./FinalDocModal";
import OtherDocModal from "./OtherDocModal";

//consts
import { toast } from "react-toastify";
import moment from "moment";
import FilterState, { sortOrder, sortBy } from "./FilterState";

//helpers
import { StatusString, totalAmount } from "../../common/consts/ProtaxHelpers";
import Helper from "../../common/consts/Helper";
import _ from "lodash";

//images
import NotFound from "../../assets/images/notFound.png";

//modals
import OrderStatus from "../../common/components/orderStatus";
import OrderPercent from "../../common/components/orderPercent";
import { AimOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import useEffectAfterMount from "../../common/hooks/useEffectAfterMount";

const { TabPane } = Tabs;
const { Option } = Select;

const filtersToSendState = {
  preferredContactMethodFilter: [],
  preferredReviewMethodFilter: [],
  genderFilter: [],
  maritalStatusFilter: [],
  checklistFilter: [],
  minDate: "",
  maxDate: "",
};

const initialPagination = {
  limit: 10,
  skip: 0,
  current: 1,
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Order(props) {
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [finalDoc, setFinalDoc] = useState({
    title: "",
    document: null,
  });
  const [currentOrder, setCurrentOrder] = useState({});

  const [completedOrders, setCompletedOrders] = useState([]);
  const [totalCompletedOrders, setTotalCompletedOrders] = useState([]);

  const [eFileOrders, setEFileOrders] = useState([]);
  const [totalEFileOrders, setTotalEFileOrders] = useState([]);

  const [currentTab, setCurrentTab] = useState("1");

  const [paginationParams, setPaginationParams] = useState(initialPagination);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  //filter    => modal
  const [filters, setFilters] = useState(FilterState);

  //staff assign order    => modal
  const [assignStaff, setAssignStaff] = useState([]);

  //to update order status    => modal
  const [currentStatus, setCurrentStatus] = useState("");
  const [orderId, setOrderId] = useState("");

  //fullname
  const [orderFullName, setOrderFullName] = useState("");

  //order percentage variables
  const [orderPercent, setOrderPercent] = useState({
    percent: "",
    id: "",
    fullName: "",
  });

  const [isChargingUser, setIsChargingUser] = useState({
    isCharging: false,
    index: null,
  });

  //sort options
  const [sort, setSort] = useState({
    sortBy: "createdAt",
    sortOrder: -1,
  });

  const [filtersToSend, setFiltersToSend] = useState(filtersToSendState);

  const query = useQuery();

  const tabQuery = query.get("tab");
  const pageQuery = query.get("page");
  const limitQuery = query.get("limit");

  useEffect(() => {
    if (tabQuery) {
      setCurrentTab(tabQuery);

      if (pageQuery || limitQuery) {
        setTimeout(() => {
          setPaginationParams((prev) => ({
            ...prev,
            skip: ((pageQuery || 1) - 1) * (+limitQuery || prev?.limit),
            current: +(pageQuery || 1),
            limit: +limitQuery || prev?.limit,
          }));
        }, 100);
      }
    }
  }, [tabQuery, pageQuery, limitQuery]);

  let debouncedSearch = useCallback(
    _.debounce((search) => {
      fetchOrders(search);
    }, 1000),
    []
  );

  useEffect(() => {
    fetchOrders();
  }, [paginationParams, filtersToSend, sort]);

  useEffectAfterMount(() => {
    debouncedSearch(search);
  }, [search]);

  useEffect(() => {
    setFilters(FilterState);
    setPaginationParams({
      ...paginationParams,
      ...initialPagination,
    });
    setSearch("");
    setFiltersToSend({
      ...filtersToSend,
      ...filtersToSendState,
    });
  }, [currentTab]);

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title"> Orders</h4>
              <div className="update">
                {assignStaff.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary btn-round"
                    data-toggle="modal"
                    data-target="#AssignModal"
                  >
                    Assign
                  </button>
                )}
              </div>
            </div>
            <div className="card-header">
              <div className="input-group no-border">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="nc-icon nc-zoom-split"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-header">
              <div className="row">
                <div className="col-12">
                  <label>Sort by</label>
                </div>
                <div className="col-12">
                  <Select
                    style={{ minWidth: 140 }}
                    placeholder="Sorty By"
                    className="text-capitalize"
                    value={sort.sortBy}
                    onChange={(value) => {
                      setSort({
                        ...sort,
                        sortBy: value,
                      });
                    }}
                  >
                    {sortBy.map((item, index) => {
                      return (
                        <Option
                          className="text-capitalize"
                          key={index}
                          value={item.value}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                  <Select
                    style={{ minWidth: 140 }}
                    placeholder="Sorty By"
                    className="text-capitalize ml-2"
                    value={sort.sortOrder}
                    onChange={(value) => {
                      setSort({
                        ...sort,
                        sortOrder: value,
                      });
                    }}
                  >
                    {sortOrder.map((item, index) => {
                      return (
                        <Option
                          className="text-capitalize"
                          key={index}
                          value={item.value}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div>
                <Tabs
                  defaultActiveKey="1"
                  activeKey={currentTab}
                  onChange={(key) => {
                    props.history.push(`/order?tab=${key}`);
                  }}
                >
                  <TabPane tab="Orders" key="1">
                    <div className="table-responsive table-scroll">
                      <table className="table">
                        {renderTableHeader()}
                        {renderTableBody(orders)}
                      </table>
                    </div>
                  </TabPane>
                  <TabPane tab="Completed" key="2">
                    <div className="table-responsive table-scroll">
                      <table className="table">
                        {renderTableHeader()}
                        {renderTableBody(completedOrders)}
                      </table>
                    </div>
                  </TabPane>
                  <TabPane tab="File E Filed" key="3">
                    <div className="table-responsive table-scroll">
                      <table className="table">
                        {renderTableHeader()}
                        {renderTableBody(eFileOrders)}
                      </table>
                    </div>
                  </TabPane>
                </Tabs>
                {renderNotFound()}
                {(orders.length > 0 ||
                  completedOrders.length > 0 ||
                  eFileOrders.length > 0) && (
                  <Pagination
                    showSizeChanger
                    onChange={(current, limit) => {
                      window.scrollTo(0, 0);
                      props?.history?.push(
                        `/order?tab=${tabQuery}&page=${current}&limit=${limit}`
                      );
                      // if (limit !== paginationParams.limit) {
                      //   setPaginationParams({
                      //     ...paginationParams,
                      //     limit: limit,
                      //     skip: 0,
                      //     current: 1,
                      //   });
                      // } else {
                      //   setPaginationParams({
                      //     ...paginationParams,
                      //     skip: (current - 1) * paginationParams.limit,
                      //     current,
                      //   });
                      // }
                    }}
                    current={paginationParams.current}
                    total={
                      currentTab === "1"
                        ? totalOrders
                        : currentTab === "2"
                        ? totalCompletedOrders
                        : totalEFileOrders
                    }
                    pageSize={paginationParams.limit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-button"
        data-toggle="modal"
        data-target="#FilterModal"
      >
        <i className="fas fa-filter fa-lg text-light" />
      </div>
      <Filter
        setFilters={setFilters}
        filters={filters}
        filtersToSend={filtersToSend}
        setFiltersToSend={setFiltersToSend}
      />
      <AssignOrder fetchOrders={fetchOrders} assignStaff={assignStaff} />
      <OrderStatus
        fetchOrders={fetchOrders}
        setCurrentStatus={setCurrentStatus}
        setOrderId={setOrderId}
        orderId={orderId}
        currentStatus={currentStatus}
        orderFullName={orderFullName}
      />
      <OrderPercent
        fetchOrders={fetchOrders}
        setOrderPercent={setOrderPercent}
        orderPercent={orderPercent}
      />
      <FinalDocModal // Upload Client’s Copy Modal
        fetchOrders={fetchOrders}
        orderId={orderId}
        finalDoc={finalDoc}
        spouseDetails={currentOrder}
      />
      <OtherDocModal // View additional documents Modal
        order={currentOrder}
        fetchOrders={fetchOrders}
        orderId={orderId}
      />
    </div>
  );
  function handleAssignStaffCheck(e, id, staffInfo) {
    let { checked } = e.target;
    if (checked) {
      let confirmation;
      if (staffInfo?.length > 0) {
        confirmation = window.confirm(
          `This order is already assigned to a Staff Member - ${
            staffInfo[0] ? staffInfo[0].name : " - "
          }. Are you sure you want to reassign this order?`
        );
        if (confirmation) {
          setAssignStaff([...assignStaff, id]);
        }
      } else {
        setAssignStaff([...assignStaff, id]);
      }
    } else {
      let newAssignStaff = assignStaff.slice();
      let index = newAssignStaff.findIndex((e) => e === id);
      if (index !== -1) {
        newAssignStaff.splice(index, 1);
        setAssignStaff(newAssignStaff);
      }
    }
  }
  function notFoundCondition() {
    let arrayCond = false;
    if (currentTab === "1" && orders.length === 0) {
      arrayCond = true;
    } else if (currentTab === "2" && completedOrders.length === 0) {
      arrayCond = true;
    }
    return arrayCond;
  }
  function renderNotFound() {
    if (!isLoading && notFoundCondition()) {
      return (
        <div className="row">
          <div className="col-md-12 text-center">
            <img src={NotFound} className="img-fluid" width="300" />
          </div>
          <div className="col-md-12 text-center">
            <span>No Orders Found!!</span>
          </div>
        </div>
      );
    }
  }
  function getAmountToRefund(payment, totalAmount, baseOrder) {
    let transactions = payment?.slice() || [];
    let totalPaid = 0;
    let PaymentAmount = 0;
    for (const transaction of transactions) {
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }
    totalPaid = +totalPaid / 100;
    PaymentAmount = +totalAmount - +totalPaid - +(baseOrder?.discount || 0);
    return PaymentAmount.toFixed(2);
  }
  function renderTableBody(orders) {
    return (
      <tbody>
        {isLoading && (
          <tr className="col-12 text-center">
            <td colSpan="14">
              <Spinner />
            </td>
          </tr>
        )}
        {!isLoading &&
          orders.map((order, index) => {
            let refundAmount = getAmountToRefund(
              order.payment,
              order.totalAmount,
              order.baseOrder
            );

            const isEdited = order?.isAdminView;
            return (
              <tr key={index}>
                <td>
                  {isEdited && (
                    <div
                      style={{
                        transform: "scale(1.3)",
                      }}
                    >
                      <AimOutlined size={25} color={""} />
                    </div>
                  )}
                </td>
                <td className="text-center">
                  <Checkbox
                    onChange={(e) =>
                      handleAssignStaffCheck(e, order._id, order.staffInfo)
                    }
                    checked={assignStaff.includes(order._id) ? true : false}
                    // disabled={order.staffInfo.length > 0 ? true : false}
                  />
                </td>
                <td className="text-center">
                  {index + 1 + paginationParams.skip}
                </td>
                <td className="text-center">{order.sin}</td>
                <td className="text-center">
                  {moment(order.createdAt).format("Do MMMM, YYYY hh:mm a")}
                </td>
                <td className="text-center">{order?.userInfo[0]?.mobile}</td>
                <td className="text-center">
                  {order?.source === "web" ? (
                    <i className="fas fa-desktop fa-2x text-info"></i>
                  ) : order?.source === "ios" ? (
                    <i class="fab fa-apple fa-2x"></i>
                  ) : (
                    <i className="fab fa-android fa-2x text-success"></i>
                  )}
                </td>
                <td className="text-capitalize">
                  {`${order.firstName} ${order.lastName}`}
                </td>
                <td className="text-bold text-uppercase">
                  {StatusString[order.status]}
                </td>
                <td className="text-uppercase">
                  ${" "}
                  {totalAmount(
                    order?.baseOrder?.order,
                    order?.baseOrder?.discount
                  )}
                </td>
                <td className="text-uppercase text-bold">
                  ${" "}
                  {order?.appVersion
                    ? getPendingAmountNewApp(order)
                    : getPendingAmount(order)}
                </td>
                <td className="text-capitalize">
                  {order.staffInfo[0] ? order.staffInfo[0].name : " - "}
                </td>
                <td className="text-bold">
                  ${" "}
                  {Math.sign(refundAmount) == -1
                    ? Math.abs(refundAmount)
                    : " - "}
                </td>
                <td className="text-center">
                  {order?.userInfo[0]?.userType === 4 ? "YES" : "NO"}
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-round bg-warning"
                    onClick={() => {
                      props.history.push("/order_view", { order: order });
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-round w-full"
                    data-toggle="modal"
                    data-target="#OrderStatusModal"
                    onClick={() => {
                      setCurrentStatus(order.status);
                      setOrderId(order._id);
                      setOrderFullName(
                        `${order?.firstName} ${order?.lastName}`
                      );
                    }}
                  >
                    <i className="fas fa-certificate"></i>
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-success btn-round w-full"
                    data-toggle="modal"
                    data-target="#OrderPercentModal"
                    onClick={() => {
                      setOrderPercent({
                        ...orderPercent,
                        percent: order.percentageCompleted,
                        id: order._id,
                        fullName: `${order?.firstName} ${order?.lastName}`,
                      });
                    }}
                  >
                    <i className="fas fa-thermometer-three-quarters"></i>
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-info btn-round w-full"
                    onClick={() => {
                      props.history.push("/edit_order", { order: order });
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-info btn-round w-full"
                    onClick={() => {
                      let route = "/document_upload/";
                      const isWeb = true;
                      if (isWeb) {
                        route = "/document_upload_web/";
                      }
                      props.history.push(route + order._id, {
                        document1: order?.document1,
                        signature1: isWeb
                          ? order?.documentSignature1
                          : order.signature1,

                        document2: order?.document2,
                        signature2: isWeb
                          ? order?.documentSignature2
                          : order.signature2,

                        document3: order?.document3,
                        signature3: isWeb
                          ? order?.documentSignature3
                          : order.signature3,

                        document4: order?.document4,
                        signature4: isWeb
                          ? order?.documentSignature4
                          : order.signature4,

                        document5: order?.document5,
                        signature5: isWeb
                          ? order?.documentSignature5
                          : order.signature5,

                        fullName: `${order?.firstName} ${order?.lastName}`,
                      });
                    }}
                  >
                    <i className="fas fa-file-alt"></i>
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-success btn-round w-full"
                    onClick={() => {
                      if (order?.appVersion) {
                        chargeUser(order._id, index, order);
                      } else {
                        chargeWebUser(order._id, index, order);
                      }
                    }}
                    disabled={
                      isChargingUser.isCharging &&
                      isChargingUser.index === index
                    }
                  >
                    {isChargingUser.isCharging &&
                    isChargingUser.index === index ? (
                      <Spinner />
                    ) : (
                      <i className="fas fa-file-alt"></i>
                    )}
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-dark btn-round w-full"
                    data-toggle="modal"
                    data-target="#uploadFinalDoc"
                    onClick={() => {
                      setOrderId(order._id);
                      setFinalDoc({
                        title: order.finalDocumentTitle,
                        document: order.orderDocument,
                        fullName: `${order?.firstName} ${order?.lastName}`,
                        spouseDocument: order?.spouseDocument,
                      });
                      setCurrentOrder({
                        ...order,
                      });
                    }}
                  >
                    <i className="fas fa-file-alt"></i>
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-warning btn-round w-full"
                    data-toggle="modal"
                    data-target="#uploadOtherDoc"
                    onClick={() => {
                      setOrderId(order._id);
                      setCurrentOrder({
                        ...currentOrder,
                        ...order,
                      });
                    }}
                  >
                    <i class="fas fa-file"></i>
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  }
  function renderTableHeader() {
    return (
      <thead className="text-primary table-sticky">
        <th></th>
        <th>Assign</th>
        <th className="text-center">Sno</th>
        <th className="text-center">SIN</th>
        <th className="text-center">Date</th>
        <th className="text-center">Mobile</th>
        <th className="text-center">Source</th>
        <th className="text-center">Name</th>
        <th className="text-center">Status</th>
        <th className="text-center">Amount</th>
        <th className="text-center">Pending</th>
        <th className="text-center">Staff</th>
        <th className="text-center">Refund</th>
        <th className="text-center">Is Student</th>
        <th className="text-center">Client documents</th>
        <th className="text-center">Tax return status</th>
        <th className="text-center">Percent</th>
        <th className="text-center">Edit</th>
        <th className="text-center">Upload 183 / Authorisation</th>
        <th className="text-center">Charge user</th>
        <th className="text-center">Upload client’s copy</th>
        <th className="text-center">View additional documents</th>
      </thead>
    );
  }

  function testChargeUserCases(order) {
    let payment = order?.payment?.slice() || [];
    let totalPaid = 0;
    let myStatus = "";
    let returnStatus = false;
    for (const transaction of payment) {
      if (transaction.paymentStatus === 2) {
        myStatus = "expired";
      }
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }
    totalPaid = (totalPaid / 100).toFixed(2);
    if (totalPaid == order.totalAmount) {
      returnStatus = true;
    } else if (totalPaid !== order.totalAmount) {
      returnStatus = false;
      toast.error("User still has pending Amount!!");
    } else if (myStatus === "expired") {
      toast.error(
        "One or more payments are expired, Please tell the user to Reauthorize Payments!!"
      );
      returnStatus = false;
    }

    return returnStatus;
  }
  function chargeUserDisability() {
    //loop payment array
    //check status succeeded and 1
    //sum amount
    //condition summed amount must be equal to totalamount
  }
  function getPendingAmountNewApp(order) {
    let totalPaid = 0;
    let pendingAmount;
    let payment = order?.payment?.slice() || [];
    for (const transaction of payment) {
      if (
        (transaction.status === "succeeded" ||
          transaction?.status === "requires_capture") &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }
    totalPaid = (totalPaid / 100).toFixed(2);
    pendingAmount =
      +order.totalAmount - totalPaid - +(order?.baseOrder?.discount || 0);
    if (Math.sign(pendingAmount) === -1) pendingAmount = 0;
    return pendingAmount.toFixed(2);
  }
  function getPendingAmount(order) {
    let totalPaid = 0;
    let pendingAmount;
    let payment = order?.payment?.slice() || [];
    for (const transaction of payment) {
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }
    totalPaid = (totalPaid / 100).toFixed(2);
    pendingAmount =
      +order.totalAmount - totalPaid - +(order?.baseOrder?.discount || 0);
    if (Math.sign(pendingAmount) === -1) pendingAmount = 0;
    return pendingAmount.toFixed(2);
  }

  async function checkOrderCalculation(id) {
    try {
      const response = await Helper("/order_calculation", "POST", {
        orderId: id,
      });

      if (response?.order && response?.order?.length > 0) {
        const order = response.order[0];
        if (order?.userNeedToPay > 0) {
          alert("User has pending amount to pay");
          return false;
        } else if (order?.refundAmount > 0) {
          alert("User has pending amount to refund, Can't Charge");
          return false;
        } else
          return {
            paymentIntentId: order?.paymentInfo?.id,
          };
      }
    } catch (error) {}
  }
  function chargeWebUser(id, index, order) {
    let confirmation = window.confirm(
      "Are you sure you want to charge the user for this order?"
    );
    if (confirmation) {
      if (testChargeUserCases(order)) {
        setIsChargingUser({
          ...isChargingUser,
          isCharging: true,
          index: index,
        });
        let body = {
          orderId: id,
        };
        const urlToHit = order?.appVersion
          ? "/capture_payment_intent"
          : "/capture_payment";
        let res = Helper(urlToHit, "POST", body);
        res
          .then((response) => {
            if (response.error) {
              toast.error(response?.error?.toString());
            } else {
              toast.success("Payment has been successfully charged");
            }
          })
          .catch(() => null)
          .finally(() => {
            setIsChargingUser({
              ...isChargingUser,
              isCharging: false,
              index: null,
            });
          });
      }
    }
  }
  async function chargeUser(id, index, order) {
    let confirmation = window.confirm(
      "Are you sure you want to charge the user for this order?"
    );
    if (confirmation) {
      setIsChargingUser({
        ...isChargingUser,
        isCharging: true,
        index: index,
      });
      const isChargable = await checkOrderCalculation(id);
      let body = {
        orderId: id,
      };
      let intentBody = {
        orderId: id,
        paymentIntentId: isChargable?.paymentIntentId,
      };
      if (isChargable) {
        const isMobileApp = order?.appVersion;
        const urlToHit = isMobileApp
          ? "/capture_payment_intent"
          : "/capture_payment";
        let res = Helper(urlToHit, "POST", isMobileApp ? intentBody : body);
        res
          .then((response) => {
            if (response.error) {
              toast.error(response?.error?.toString());
            } else if (response?.raw?.message) {
              toast.error(response?.raw?.message?.toString());
            } else {
              toast.success("Payment has been successfully charged");
            }
          })
          .catch(() => null)
          .finally(() => {
            setIsChargingUser({
              ...isChargingUser,
              isCharging: false,
              index: null,
            });
          });
      } else {
        setIsChargingUser({
          ...isChargingUser,
          isCharging: false,
          index: null,
        });
      }
    }
  }
  function fetchOrders(search) {
    setIsLoading(true);
    let body = {
      limit: paginationParams.limit,
      skip: paginationParams.skip,
      searchString: search,
      ...filtersToSend,
      ...sort,
    };
    if (search === "") delete body.searchString;
    if (body.minDate === "") delete body.minDate;
    if (body.maxDate === "") delete body.maxDate;

    let res = Helper("/orders", "POST", body);
    res
      .then((response) => {
        if (response.error) toast.error(response.error);
        else {
          setOrders(response.orders);
          setTotalOrders(response.totalOrders);
          setCompletedOrders(response.completedOrders);
          setEFileOrders(response?.eFileOrders);
          setTotalEFileOrders(response?.eFileOrderCount);
          setTotalCompletedOrders(response.completedOrderCount);
        }
      })
      .catch((error) => null)
      .finally(() => setIsLoading(false));
  }
}
