import React, { Fragment } from 'react';

//ant d components
import { Select } from 'antd';

//helpers
import { allDisabilityTaxCredit } from '../../common/consts/ProtaxHelpers';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

const { Option } = Select;

function QuestionsForm(props) {

    let {
        //basic details
        questions,

        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Questions Details</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {
                        questions.map((ques, index) => {
                            return (
                                <div key={index}>
                                    {
                                        ques.questions.map((textQues, qi) => {
                                            return (
                                                <div
                                                    key={qi}
                                                >
                                                    <div
                                                        style={{ flex: 0.9 }}
                                                    >
                                                        <span>{index + 1}. </span>
                                                        {textQues.question}
                                                    </div>
                                                    <Select
                                                        placeholder="Question"
                                                        className="text-capitalize ml-2 mt-2  mb-2"
                                                        style={{ minWidth: '140px' }}
                                                        value={ques.checked}
                                                        onChange={(value) => {
                                                            handleQuestionCheckInfo(index, value)
                                                        }}
                                                    >
                                                        {
                                                            allDisabilityTaxCredit.map((item, index) => {
                                                                return (
                                                                    <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        )}
                </div>
            </div>
        </Fragment>
    )

    function handleQuestionCheckInfo(index, value) {
        let newQuestions = questions.slice();
        newQuestions[index].checked = value;
        handleChange('questions', newQuestions);
    }

}


const mapStateToProps = () => state => ({
    questions: state.Pickup.questions,
});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsForm);