import React, { useState, useEffect } from 'react';

import { Select, Checkbox } from 'antd';
import Spinner from '../../common/components/spinner';

//constatns
import { toast } from 'react-toastify';
import Helper from '../../common/consts/Helper';

const { Option } = Select;

const initalData = {
    title: '',
    image: null,
    description: ''
}

export default function Notification() {

    const [isLoading, setIsLoading] = useState(false);
    const [isNotification, setIsNotification] = useState(false);

    const [users, setUsers] = useState([]);

    const [userId, setUserId] = useState(null);
    const [isAllUsers, setIsAllUsers] = useState(false);
    const [data, setData] = useState(initalData)

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-user">
                        <div className="card-header">
                            <h5 className="card-title">Send Notification</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Select
                                            disabled={isAllUsers}
                                            value={userId}
                                            loading={isLoading}
                                            showSearch
                                            onChange={(value) => {
                                                setUserId(value)
                                            }}
                                            style={{ minWidth: '30%' }}
                                            placeholder="Select a person"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                users.map((user, index) => {
                                                    return (
                                                        <Option
                                                            value={user._id}
                                                            key={index}
                                                        >
                                                            {`${user.name} - ${user.email}`}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-md-12">
                                        <Checkbox
                                            className="mt-2"
                                            value={isAllUsers}
                                            onChange={(e) => {
                                                setIsAllUsers(e.target.checked);
                                                if (e.target.checked) {
                                                    setUserId(null);
                                                }
                                            }}
                                        >
                                            <span className="text-dark">Send Notification to all Users?</span>
                                        </Checkbox>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input
                                                type="text"
                                                className="form-control text-capitalize"
                                                placeholder="Notification Title"
                                                required
                                                value={data.title}
                                                onChange={handleChange}
                                                name="title"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                className="form-control text-capitalize"
                                                placeholder="Description"
                                                required
                                                value={data.description}
                                                onChange={handleChange}
                                                name="description"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Upload Image</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="inputGroupFile01"
                                                        accept="image/*"
                                                        onChange={handleImage}
                                                    />
                                                    <label className="custom-file-label" for="inputGroupFile01">Choose Image</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data.image && (
                                            <div className="col-md-12">
                                                <div className="form-group" style={{ flexDirection: 'column', display: 'flex' }}>
                                                    <label>Image</label>
                                                    <div
                                                        style={{ width: '250px', borderRadius: 10, position: 'relative' }}
                                                    >
                                                        <img
                                                            src={getImageSrc()}
                                                            className="img-fluid"
                                                            style={{ width: '250px', borderRadius: 10 }}
                                                        />
                                                        <div
                                                            className="checklistCross"
                                                            onClick={() => {
                                                                // deleteImage(index, ci)
                                                            }}
                                                        >
                                                            <i className="fa fa-times" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>


                                <div className="row">
                                    <div className="update ml-auto mr-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-round"
                                            disabled={isNotification}
                                        >
                                            {
                                                isNotification ? (<Spinner />) : null
                                            }
                                            Send Notification
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function submit(e) {
        e.preventDefault();
        let confirmation = window.confirm("Are you sure you want to send this notification?");
        if (confirmation) {
            setIsNotification(true);
            let body = new FormData();
            let { title, description, image } = data;
            body.append('title', title);
            body.append('description', description);
            body.append('isAll', isAllUsers?1:0);
            if (image)
                body.append('image', image);
            if (!isAllUsers)
                body.append('userId', userId)

            let res = Helper('/send_notification', 'POST', body, true);
            res.then((response) => {
                if (response.error) {
                    toast.error(response?.error?.toString());
                }
                else {
                    toast.success(response.message);
                    setData({
                        ...data,
                        ...initalData
                    });
                    setUserId('');
                    setIsAllUsers(false);
                }
            })
                .catch((error) => null)
                .finally(()=>{
                    setIsNotification(false);
                })
        }
    }
    function handleChange(e) {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }
    function getImageSrc() {
        let { image } = data;
        let url = '';
        if (typeof (image) === 'string')
            url = image
        else if (typeof (image) === 'object')
            url = URL.createObjectURL(image);
        return url
    }
    function handleImage(e) {
        let { files } = e.target;
        if (files.length > 0) {
            setData({
                ...data,
                image: files[0]
            });
        }
    }
    function fetchUser() {
        setIsLoading(true);
        let body = {
            userType: 2,
            isAll: 1
        }
        let res = Helper('/users', 'POST', body);
        res.then((response) => {
            if (response.error) toast.error(response.error)
            else {
                setUsers(response.users);
                // setTotalUsers(response.totalUsers);
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false));
    }

}