import React from 'react';

import _ from 'lodash';

import moment from 'moment';

export default function BasicFormView(props) {
    let { basicInfo, title } = props;

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h5>{title}</h5>
                </div>
            </div>
            <div className="row">
                {
                    Object.keys(basicInfo).map((key, index) => {
                        return (
                            <div className="col-6 d-flex" key={index}>
                                <div className="text-bold" style={{ minWidth: '100px' }}>
                                    {_.startCase(key)}:
                                </div>
                                <div className="ml-2 text-capitalize">{basicInfo[key]?.toString() ? basicInfo[key].toString() : ' - '}</div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}