import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../common/components/spinner';
import Helper from '../../common/consts/Helper';

export default function AddStaff(props) {

    const [data, setData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        image: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [staffId, setStaffId] = useState('');

    let { name, email, mobile, password, image } = data;

    useEffect(() => {
        let { state } = props.history.location;
        if (state && state.edit) {
            setIsEdit(true);
            let { staff } = state;
            setData({
                ...data,
                name: staff?.name,
                email: staff?.email,
                mobile: staff?.mobile,
                image: staff?.image
            });
            setStaffId(staff._id);
        }
    }, []);

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-user">
                        <div className="card-header">
                            <h5 className="card-title">
                                <span
                                    onClick={() => {
                                        props.history.push('/staff')
                                    }}
                                    className="cursor-pointer"
                                >
                                    <i className="fa fa-chevron-left mr-3" />
                                </span>{isEdit ? 'Edit' : 'Add'} Staff Member</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={addStaff}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                className="form-control text-capitalize"
                                                placeholder="Ex - John Smith"
                                                name="name"
                                                value={name}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Ex - staff@gmail.com"
                                                name="email"
                                                value={email}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Ex - 98372829"
                                                name="mobile"
                                                required
                                                value={mobile}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {
                                        !isEdit ? (
                                            <div className="col-md-12">
                                                <div className="form-control p-0 m-0" style={{ borderWidth: 0 }}>
                                                    <label>Password</label>
                                                    <div className="input-group">
                                                        <input
                                                            type={isPasswordVisible ? 'text' : 'password'}
                                                            className="form-control"
                                                            placeholder="**********"
                                                            name="password"
                                                            required
                                                            value={password}
                                                            onChange={handleChange}
                                                        />
                                                        <div
                                                            class="input-group-append cursor-pointer"
                                                            onClick={() => { setIsPasswordVisible(previousState => !previousState) }}
                                                        >
                                                            <span class="input-group-text">
                                                                {
                                                                    isPasswordVisible ? (
                                                                        <i className="fa fa-eye" />
                                                                    ) : (
                                                                            <i className="fas fa-eye-slash" />
                                                                        )
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Upload</label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="inputGroupFile01"
                                                        accept="image/*"
                                                        onChange={handleImage}
                                                        required
                                                    />
                                                    <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data.image && (
                                            <div className="col-md-12">
                                                <div className="form-group" style={{ flexDirection: 'column', display: 'flex' }}>
                                                    <label>Image</label>
                                                    <img
                                                        src={getImageSrc()}
                                                        className="img-fluid"
                                                        style={{ width: '250px', borderRadius: 10 }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="row">
                                    <div className="update ml-auto mr-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-round"
                                            disabled={isLoading}
                                        >
                                            {isLoading && <Spinner />}{isEdit ? 'Edit' : 'Add'} Staff
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    function addStaff(e) {
        e.preventDefault();
        setIsLoading(true);
        let { name, email, mobile, password, image } = data;

        let body = new FormData();

        body.append('name', name);
        body.append('email', email);
        body.append('mobile', mobile);
        body.append('image', image);
        body.append('userType', 3);

        let res;

        if (isEdit) {
            body.append('userId', staffId);
            res = Helper('/edit_staff', 'POST', body, true);
        }
        else {
            body.append('password', password);
            res = Helper('/add_staff', 'POST', body, true);
        }
        res.then((response) => {
            if (response.error) toast.error(response.error);
            else {
                toast.success(response.message);
                if (isEdit) {
                    props.history.goBack();
                }
                else {
                    setData({
                        ...data,
                        name: '',
                        email: '',
                        mobile: '',
                        password: '',
                        image: null,
                    })
                }
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false))
    }
    function handleChange(e) {
        let { value, name } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }
    function getImageSrc() {
        let { image } = data;
        let url = '';
        if (typeof (image) === 'string')
            url = image
        else if (typeof (image) === 'object')
            url = URL.createObjectURL(image);
        return url
    }
    function handleImage(e) {
        let { files } = e.target;
        if (files.length > 0) {
            setData({
                ...data,
                image: files[0]
            });
        }
    }
}