import React, { Fragment } from 'react';

//ant d components
import { DatePicker, Select } from 'antd';

//helpers
import { allMaritalStatus, allFillingForSpouse, allPostSecondaryStudent, allDisabilityTaxCredit } from '../../common/consts/ProtaxHelpers';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

//consts
import moment from 'moment';

const { Option } = Select;

function BasicDetails(props) {

    let {

        //basic details
        maritalStatus,
        fillingForSpouse,
        spouseInfo,

        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Spouse Details</span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Marital Status</label>
                        <Select
                            placeholder="Marital Status"
                            className="text-capitalize w-100"
                            value={maritalStatus}
                            onChange={(value) => {
                                handleChange('maritalStatus', value)

                            }}
                        >
                            {
                                allMaritalStatus.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Are you filling for Spouse?</label>
                        <Select
                            placeholder="Marital Status"
                            className="text-capitalize w-100"
                            value={fillingForSpouse}
                            onChange={(value) => {
                                handleChange('fillingForSpouse', value)
                            }}
                        >
                            {
                                allFillingForSpouse.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>First Name</label>
                        <input
                            value={spouseInfo.firstName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Legal Name"
                            name="firstName"
                            onChange={handleSpouseInfoChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Middle Name</label>
                        <input
                            value={spouseInfo.middleName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Middle Name"
                            name="middleName"
                            onChange={handleSpouseInfoChange}
                        />
                    </div>
                </div>

                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Last Name</label>
                        <input
                            value={spouseInfo.lastName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Last Name"
                            name="lastName"
                            onChange={handleSpouseInfoChange}
                        />
                    </div>
                </div>
                {
                    !fillingForSpouse ? (
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>Net Income</label>
                                <input
                                    value={spouseInfo.netIncome}
                                    type="text"
                                    className="form-control text-capitalize"
                                    placeholder="Net Income"
                                    name="netIncome"
                                    onChange={handleSpouseInfoChange}
                                />
                            </div>
                        </div>
                    ) : null
                }
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>SIN</label>
                        <input
                            value={spouseInfo.sin}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="SIN"
                            name="sin"
                            onChange={handleSpouseInfoChange}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>DOB</label>
                        <DatePicker
                            value={moment(spouseInfo.dob, 'YYYY-MM-DD')}
                            style={{ width: '100%', height: '40px' }}
                            onChange={(value, datestring) => {
                                handleDates('dob', datestring)
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Post-Secondary Student</label>
                        <Select
                            placeholder="Marital Status"
                            className="text-capitalize w-100"
                            value={spouseInfo.postSecondaryStudent}
                            onChange={(value) => {
                                handleRadios('postSecondaryStudent', value);
                            }}
                        >
                            {
                                allPostSecondaryStudent.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Disability Tax Credit</label>
                        <Select
                            placeholder="Marital Status"
                            className="text-capitalize w-100"
                            value={spouseInfo.disabled}
                            onChange={(value) => {
                                handleRadios('disabled', value);
                            }}
                        >
                            {
                                allDisabilityTaxCredit.map((item, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
        </Fragment>
    )

    function handleRadios(name, value) {
        let newSpouseInfo = {
            ...spouseInfo,
            [name]: value
        }
        handleChange('spouseInfo', newSpouseInfo)
    }
    function handleDates(name, value) {
        let newSpouseInfo = {
            ...spouseInfo,
            [name]: value
        }
        handleChange('spouseInfo', newSpouseInfo)
    }
    function handleSpouseInfoChange(e) {
        let { name, value } = e.target;
        let newSpouseInfo = {
            ...spouseInfo,
            [name]: value
        }
        handleChange('spouseInfo', newSpouseInfo)
    }
    function handleText(e) {
        let { value, name } = e.target;
        handleChange(name, value);
    }
}


const mapStateToProps = () => state => ({

    //spouse details
    maritalStatus: state.Pickup.maritalStatus,
    fillingForSpouse: state.Pickup.fillingForSpouse,
    spouseInfo: state.Pickup.spouseInfo

});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);