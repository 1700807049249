import {
  PICKUP_HANDLE_CHANGE,
  PICKUP_RESET_STATE,
  SET_EDIT_STATE,
  HANDLE_MULTIPLE_CHANGE
} from './types';

export const handleChange = (name, payload) => ({
  type: PICKUP_HANDLE_CHANGE,
  payload,
  name
});

export const resetPickupState = () => ({
  type: PICKUP_RESET_STATE,
  payload: {}
});

export const setEditState=(state)=>({
  type: SET_EDIT_STATE,
  payload: state
})


export const handleMultipleChange=(state)=>({
  type: HANDLE_MULTIPLE_CHANGE,
  payload: state,
})