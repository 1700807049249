import React, { useEffect, useState } from "react";
import moment from "moment";

//forms
import BasicFormView from "./Forms/BasicForm";
import DependendantsForm from "./Forms/DependendantsForm";
import QuestionsForm from "./Forms/QuestionsForm";
import Checklist from "./Forms/Checklist";
import PayemntMode from "./Forms/PaymentMode";
import Helper from "../../common/consts/Helper";

export default function ViewOrder(props) {
  let form = props.history.location?.state?.order;


  const [currentForm, setCurrentForm] = useState("1");

  let basicInfo = {
    gender: form.gender === 0 ? "Male" : "Female",
    firstName: form.firstName,
    middleName: form.middleName,
    lastName: form.lastName,
    sin: form.sin,
    dob: moment(form.dob).format("Do MMMM, YYYY"),
    address: form.address,
    city: form.city,
    postalCode: form.postalCode,
    province: form.province,
    maritalStatus: getMaritalStatus(form.maritalStatus),
  };

  let contactInfo = {
    preferredContactMobile: form.preferredContactMobile,
    preferredContactEmail: form.preferredContactEmail,
    preferredContactMethod:
      form.preferredContactMethod === 0 ? "Mobile" : "Email",
    // bestTimeToCall: moment(form.bestTimeToCall).format('hh:mm A'),
    alternateMobileNumber: form.alternateMobileNumber,
    preferredReviewMethod:
      form.preferredReviewMethod === 0 ? "In Person" : "By Phone",
    timeSlot: form?.timeSlot,
    preferredDate: moment(form?.preferredDate).format("Do MMMM, YYYY"),
  };

  let spouseInfo = {
    fillingForSpouse: form.fillingForSpouse ? "Yes" : "No",
    ...form.spouseInfo,
    dob: moment(form.spouseInfo.dob).format("Do MMMM, YYYY"),
    postSecondaryStudent: form.spouseInfo.postSecondaryStudent ? "Yes" : "No",
    disabled: form.spouseInfo.disabled ? "Yes" : "No",
  };

  let dependants = [...form.dependants];
  let questions = [...form.questions];

  let slipsChecklist = [...form.slipsChecklist];
  let receiptsChecklist = [...form.receiptsChecklist];
  let otherDocChecklist = [...form.otherDocChecklist];
  let spouseSlipsChecklist = [...form.spouseSlipsChecklist];
  let spouseReceiptsChecklist = [...form.spouseReceiptsChecklist];
  let spouseOtherDocChecklist = [...form.spouseOtherDocChecklist];
  let otherInfo = { anyOtherInfo: form.anyOtherInfo };
  let baseOrder = [...form.baseOrder.order];
  let discount = form?.baseOrder?.discount || 0;

  function getMaritalStatus(maritalStatus) {
    if (maritalStatus === 0) return "Single";
    else if (maritalStatus === 1) return "Married";
    else if (maritalStatus === 2) return "Common-Law";
    else if (maritalStatus === 3) return "Widowed";
    else if (maritalStatus === 4) return "Seperated";
    else if (maritalStatus === 5) return "Divorced";
    else return "-";
  }

  async function changeOrderViewStatus(adminView, staffView) {
    try {
      const res = Helper("/set_order_view_status", "POST", {
        orderId: form?._id,
        isStaffView: staffView,
        isAdminView: adminView,
      });
    } catch (error) {}
  }

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === "1" && form?.isAdminView) {
      changeOrderViewStatus(false, form?.isStaffView);
    } else if (role === "3" && form?.isStaffView) {
      changeOrderViewStatus(form?.isAdminView, false);
    }
  }, [form]);

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-8 m-auto">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title text-capitalize">
                <span
                  onClick={() => {
                    props.history.goBack();
                  }}
                  className="cursor-pointer"
                >
                  <i
                    className="fa fa-chevron-left mr-3"
                    style={{ fontSize: "15px" }}
                  />
                </span>
                Order Details - {form?.firstName + " " + form?.lastName}
              </h5>
              <div className="d-flex">
                {currentForm !== "1" && (
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      if (currentForm === "12" && !form.fillingForSpouse) {
                        setCurrentForm("8");
                      } else if (
                        currentForm === "4" &&
                        form.maritalStatus !== 1 &&
                        form.maritalStatus !== 2
                      ) {
                        setCurrentForm("2");
                      } else {
                        setCurrentForm((+currentForm - 1).toString());
                      }
                    }}
                  >
                    Previous
                  </button>
                )}
                {currentForm !== "13" && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      if (currentForm === "8" && !form.fillingForSpouse) {
                        setCurrentForm("12");
                      } else if (
                        currentForm === "2" &&
                        form.maritalStatus !== 1 &&
                        form.maritalStatus !== 2
                      ) {
                        setCurrentForm("4");
                      } else {
                        setCurrentForm((+currentForm + 1).toString());
                      }
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>

            <div className="card-body">{renderForm()}</div>
          </div>
        </div>
      </div>
    </div>
  );
  function renderForm() {
    if (currentForm === "1")
      return (
        <BasicFormView
          {...props}
          basicInfo={basicInfo}
          title="Basic Information"
        />
      );
    else if (currentForm === "2")
      return (
        <BasicFormView
          {...props}
          basicInfo={contactInfo}
          title="Contact Information"
        />
      );
    else if (currentForm === "3")
      return (
        <BasicFormView
          {...props}
          basicInfo={spouseInfo}
          title="Spouse Information"
        />
      );
    else if (currentForm === "4")
      return (
        <DependendantsForm
          {...props}
          dependants={dependants}
          title="Dependents Information"
        />
      );
    else if (currentForm === "5")
      return (
        <QuestionsForm
          {...props}
          questions={questions}
          fillingForSpouse={form.fillingForSpouse}
          title="Questions"
        />
      );
    else if (currentForm === "6")
      return <Checklist {...props} checklist={slipsChecklist} title="Slips" />;
    else if (currentForm === "7")
      return (
        <Checklist {...props} checklist={receiptsChecklist} title="Receipts" />
      );
    else if (currentForm === "8")
      return (
        <Checklist
          {...props}
          checklist={otherDocChecklist}
          title="Other Documents"
          tFormVariables={{
            T776User1: form?.T776User1 || null,
            T776User2: form?.T776User2 || null,
            T776User3: form?.T776User3 || null,
            T776User4: form?.T776User4 || null,
            T776User5: form?.T776User5 || null,
            T776User6: form?.T776User6 || null,
            T776User7: form?.T776User7 || null,
            T776User8: form?.T776User8 || null,
            T776User9: form?.T776User9 || null,
            T776User10: form?.T776User10 || null,
            T777User1: form?.T777User1 || null,
            T777User2: form?.T777User2 || null,
            T777User3: form?.T777User3 || null,
            T777User4: form?.T777User4 || null,
            T777User5: form?.T777User5 || null,
            T777User6: form?.T777User6 || null,
            T777User7: form?.T777User7 || null,
            T777User8: form?.T777User8 || null,
            T777User9: form?.T777User9 || null,
            T777User10: form?.T777User10 || null,
            T2125User1: form?.T2125User1 || null,
            T2125User2: form?.T2125User2 || null,
            T2125User3: form?.T2125User3 || null,
            T2125User4: form?.T2125User4 || null,
            T2125User5: form?.T2125User5 || null,
            T2125User6: form?.T2125User6 || null,
            T2125User7: form?.T2125User7 || null,
            T2125User8: form?.T2125User8 || null,
            T2125User9: form?.T2125User9 || null,
            T2125User10: form?.T2125User10 || null,
          }}
        />
      );
    else if (currentForm === "9")
      return (
        <Checklist
          {...props}
          checklist={spouseSlipsChecklist}
          title="Spouse Slips"
        />
      );
    else if (currentForm === "10")
      return (
        <Checklist
          {...props}
          checklist={spouseReceiptsChecklist}
          title="Spouse Receipts"
        />
      );
    else if (currentForm === "11")
      return (
        <Checklist
          {...props}
          checklist={spouseOtherDocChecklist}
          title="Spouse Other Documents"
          tFormVariables={{
            T776Spouse1: form?.T776Spouse1 || null,
            T776Spouse2: form?.T776Spouse2 || null,
            T776Spouse3: form?.T776Spouse3 || null,
            T776Spouse4: form?.T776Spouse4 || null,
            T776Spouse5: form?.T776Spouse5 || null,
            T776Spouse6: form?.T776Spouse6 || null,
            T776Spouse7: form?.T776Spouse7 || null,
            T776Spouse8: form?.T776Spouse8 || null,
            T776Spouse9: form?.T776Spouse9 || null,
            T776Spouse10: form?.T776Spouse10 || null,
            T777Spouse1: form?.T777Spouse1 || null,
            T777Spouse2: form?.T777Spouse2 || null,
            T777Spouse3: form?.T777Spouse3 || null,
            T777Spouse4: form?.T777Spouse4 || null,
            T777Spouse5: form?.T777Spouse5 || null,
            T777Spouse6: form?.T777Spouse6 || null,
            T777Spouse7: form?.T777Spouse7 || null,
            T777Spouse8: form?.T777Spouse8 || null,
            T777Spouse9: form?.T777Spouse9 || null,
            T777Spouse10: form?.T777Spouse10 || null,
            T2125Spouse1: form?.T2125Spouse1 || null,
            T2125Spouse2: form?.T2125Spouse2 || null,
            T2125Spouse3: form?.T2125Spouse3 || null,
            T2125Spouse4: form?.T2125Spouse4 || null,
            T2125Spouse5: form?.T2125Spouse5 || null,
            T2125Spouse6: form?.T2125Spouse6 || null,
            T2125Spouse7: form?.T2125Spouse7 || null,
            T2125Spouse8: form?.T2125Spouse8 || null,
            T2125Spouse9: form?.T2125Spouse9 || null,
            T2125Spouse10: form?.T2125Spouse10 || null,
          }}
        />
      );
    else if (currentForm === "12")
      return (
        <BasicFormView
          {...props}
          basicInfo={otherInfo}
          title="Other Information"
        />
      );
    else if (currentForm === "13")
      return (
        <PayemntMode
          {...props}
          baseOrder={baseOrder}
          discount={discount}
          title="Payment"
        />
      );
  }
}
