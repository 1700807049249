import React, { Fragment, useState } from 'react';

//ant d components
import { DatePicker, Select } from 'antd';

//helpers
import { allDisabilityTaxCredit } from '../../common/consts/ProtaxHelpers';
import Helper from '../../common/consts/Helper';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

//consts
import moment from 'moment';
import { BASE_URL } from '../../common/consts/Config';
import { toast } from 'react-toastify';

import Spinner from '../../common/components/spinner';

const { Option } = Select;

function BasicDetails(props) {

    const [isUploading, setIsUploading] = useState({
        isUploading: false,
        isUploadingIndex: null
    });

    let {

        //basic details
        slipsChecklist,
        firstName,

        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Income Slips</span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {
                        slipsChecklist.map((checklist, index) => {
                            return (
                                <div key={index}>
                                    <div>
                                        <span>{index + 1}. </span>
                                        {checklist.title}
                                    </div>
                                    <Select
                                        placeholder="Select"
                                        className="text-capitalize  ml-2 mt-2 mb-2"
                                        style={{ minWidth: '140px' }}
                                        value={checklist.checked}
                                        onChange={(value) => {
                                            let newSlips = slipsChecklist.slice();
                                            newSlips[index].checked = value;
                                            handleChange('slipsChecklist', newSlips);
                                        }}
                                    >
                                        {
                                            allDisabilityTaxCredit.map((item, index) => {
                                                return (
                                                    <Option className="text-capitalize" key={index} value={item.value}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                    {
                                        (checklist.checked && checklist.type === 'images') ? (
                                            <div>
                                                <div className="checklistImageContainer">
                                                    {
                                                        checklist.images.map((image, ci) => {
                                                            return (
                                                                <div
                                                                    className="checklistImage" key={ci}>
                                                                    <img src={BASE_URL + image} className="checklistImage ml-0" />
                                                                    <div
                                                                        className="checklistCross"
                                                                        onClick={() => {
                                                                            deleteImage(index, ci)
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-times" />
                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                    {
                                                        (isUploading.isUploading && isUploading.isUploadingIndex === index) ? (
                                                            <div className="checklistImage bg-light d-flex align-items-center justify-content-center">
                                                                <Spinner />
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Upload</label>
                                                        <div className="input-group mb-3">
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    accept="image/*"
                                                                    onChange={(e) => { handleImage(e, index) }}
                                                                />
                                                                <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            )
                        }
                        )}
                </div>
            </div>
        </Fragment>
    )
    function deleteImage(index, ci) {
        let confirmation = window.confirm('Are you sure you want to delete this image?');
        // console.log(confirmation, index)
        if (confirmation) {
            let newChecklist = slipsChecklist.slice();
            newChecklist[index].images.splice(ci, 1);
            handleChange('slipsChecklist', newChecklist);
        }
    }
    function handleImage(e, index) {
        let { files } = e.target;
        if (files.length > 0) {
            let body = new FormData();
            body.append('image', files[0]);
            setIsUploading({
                ...isUploading,
                isUploading: true,
                isUploadingIndex: index
            });
            let res = Helper('/image', 'POST', body, true);
            res.then((response) => {
                if (response.message) {
                    toast.success(response.message);
                    saveImage(response.data, index);
                }
                else if (response.error) toast.error(response.error.toString())
            })
                .catch((error) => {
                    toast.error("Something went wrong while uploading the image, Please try again later!!")
                })
                .finally(() => {
                    setIsUploading({
                        ...isUploading,
                        isUploading: false,
                        isUploadingIndex: null
                    });
                })
        }
    }

    function saveImage(url, index) {
        let newChecklist = slipsChecklist.slice();
        newChecklist[index].images.push(url);
        handleChange('slipsChecklist', newChecklist);
    }
}


const mapStateToProps = () => state => ({

    //basic details
    slipsChecklist: state.Pickup.slipsChecklist,
    firstName: state.Pickup.firstName,

});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);