import React, { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../../common/consts/Config';
import Helper from '../../common/consts/Helper';
import { toast } from 'react-toastify';

export default function OtherDocModal({ orderId, fetchOrders, order }) {

    const closeRef = useRef();
    const [document, setDocument] = useState(null);
    const [title, setTitle] = useState('');

    const [documentUpload, setDocumentUpload] = useState([]);
    const [isLoading, setIsLoading] = useState({
        loading: false,
        index: null
    })

    useEffect(() => {
        getDocumentUpload();
    }, [order]);

    function getDocumentUpload() {
        if (order && Object.keys(order).length > 0) {
            let newDocuments = [];
            let checklistsToCheck = [
                ...order?.otherDocChecklist,
                ...order?.spouseOtherDocChecklist
            ];

            for (const list of checklistsToCheck) {
                if (list?.showEmailButton && list?.checked) {
                    newDocuments.push({
                        name: `${list.title}`,
                        file: getOrderDocument(list?.documentType),
                        documentType: list?.documentType,
                        isAlreadyPresent: getOrderDocument(list?.documentType) ? true : false
                    });
                }
            }
            setDocumentUpload(newDocuments)
        }
    }

    function getOrderDocument(documentType) {
        let myDocVar = `orderDocument${documentType}`;
        let orderDocumentExist = null;
        if (order[myDocVar]) {
            orderDocumentExist = {
                name: order[myDocVar]
            }
        }
        return orderDocumentExist;
    }

    return (
        <div className="modal fade" id="uploadOtherDoc" tabindex="-1" role="dialog" aria-labelledby="uploadOtherDocLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <form>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="uploadOtherDocLabel">Upload Additional Documents</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row" >
                                <div className="col-12 mb-3">
                                    <h6 className="text-bold">File - {order?.firstName + ' ' + order.lastName}</h6>
                                </div>
                                {
                                    documentUpload.length === 0 ? (
                                        <div className="col-md-12 text-center">
                                            <p>No Documents Selected by User!!</p>
                                        </div>
                                    ) : null
                                }
                                {
                                    documentUpload.map((doc, index) => {
                                        return (
                                            <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Upload - {doc.name}</label>
                                                        <div className="input-group mb-3">
                                                            <div className="custom-file">
                                                                <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    accept="application/pdf"
                                                                    onChange={(e) => {
                                                                        handleDoc(e, index)
                                                                    }}
                                                                />
                                                                <label className="custom-file-label" for="inputGroupFile01">{doc?.file?.name?.replace('/images/', '') || 'Choose File'}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-end">
                                                    {
                                                        doc?.isAlreadyPresent ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    window.open(`${BASE_URL}${doc?.file?.name}`, '_blank');
                                                                }}
                                                            >
                                                                VIEW
                                                            </button>
                                                        ) : null
                                                    }
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => { uploadDocument(index, doc.documentType) }}
                                                        disabled={isLoading.loading && isLoading.index === index}
                                                    >
                                                        {isLoading.loading && isLoading.index === index ? 'Wait...' : 'Upload'}
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                ref={closeRef}
                            >
                                Close
                        </button>
                        </div> */}
                    </div>
                </form>
            </div>
        </div>
    )
    function uploadDocument(index, docType) {
        setIsLoading({
            ...isLoading,
            loading: true,
            index
        });
        let body = new FormData();
        body.append('orderId', order?._id);
        body.append('documentType', docType);
        body.append('document', documentUpload[index].file);
        let res = Helper('/upload_order_document', 'POST', body, true);
        res.then((response) => {
            if (response?.error) {
                toast.error(response?.error?.toString());
            }
            else {
                toast.success(response?.message);
            }
        })
            .catch((error) => null)
            .finally(() => {
                setIsLoading({
                    ...isLoading,
                    loading: false,
                    index: null
                })
            })
    }
    function submit(e) {
        e.preventDefault();
        setIsLoading(true);
        let body = new FormData();
        body.append('orderId', orderId);
        body.append('title', title);
        body.append('document', document);

        let res = Helper('/add_order_document', 'POST', body, true);
        res.then((response) => {
            if (response?.error) {
                toast.error(response.error);
            }
            else {
                fetchOrders();
                toast.success(response?.message);
                closeRef.current.click();
            }
        })
            .catch((error) => { })
            .finally(() => setIsLoading(false))
    }
    function handleDoc(e, index) {
        let { files } = e.target;
        if (files.length > 0) {
            let newDocumentsToUpload = documentUpload.slice();
            newDocumentsToUpload[index].file = files[0];
            setDocumentUpload(newDocumentsToUpload);
        }
    }
    function getImageSrc() {
        let url = '';
        if (typeof (document) === 'string')
            url = BASE_URL + document
        else if (typeof (document) === 'object')
            url = URL.createObjectURL(document);
        return url
    }
}