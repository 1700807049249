import React, { useEffect, useState, useCallback } from 'react';

//components
import { Pagination } from 'antd';
import { Switch } from 'antd';
import Spinner from '../../common/components/spinner';

//consts
import { BASE_URL } from '../../common/consts/Config';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Helper from '../../common/consts/Helper';
import _ from 'lodash';

//images
import UserProfileImage from '../../assets/images/avtar.png';
import NotFound from '../../assets/images/notFound.png';
import StaffPassword from '../../common/components/staffPassword';

export default function Staff(props) {

    const [staff, setStaff] = useState([]);
    const [paginationParams, setPaginationParams] = useState({
        limit: 10,
        skip: 0,
        current: 1
    });
    const [searchString, setSearchString] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isToggling, setIsToggling] = useState({
        isToggling: false,
        isTogglingIndex: null
    })
    const [totalUsers, setTotalUsers] = useState(0);

    const [currentUserId, setCurrentUserId] = useState('');

    let debouncedSearch = useCallback(_.debounce((searchString)=>{fetchStaff(searchString)}, 1000), []);

    useEffect(() => {
        fetchStaff();
    }, [paginationParams]);

    useEffect(() => {
        debouncedSearch(searchString);
    }, [searchString]);
    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title"> Staff Members</h4>
                            <div className="update">
                                <Link to="/add_staff">
                                    <button type="button" className="btn btn-primary btn-round">Add Staff</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-header">
                            <form>
                                <div className="input-group no-border">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        value={searchString}
                                        onChange={(e) => { setSearchString(e.target.value) }}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="nc-icon nc-zoom-split"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">

                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                                </th>
                                        <th className="text-center">
                                            Image
                                                </th>
                                        <th className="text-center">
                                            Name
                                                </th>
                                        <th className="text-center">
                                            Email
                                                </th>
                                        <th className="text-center">
                                            Mobile
                                                </th>
                                        <th className="text-center">
                                            Inactive
                                        </th>
                                        <th className="text-center">
                                            New Password
                                        </th>
                                        <th className="text-center">
                                            Orders
                                        </th>
                                        <th className="text-center">
                                            Edit
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && (
                                                <tr className="col-12 text-center">
                                                    <td colSpan="9">
                                                        <Spinner />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            staff.map((user, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-center">
                                                            <img
                                                                src={user.profileImage ? (BASE_URL + user.profileImage) : (UserProfileImage)}
                                                                className="img-fluid"
                                                                width="150"
                                                            />
                                                        </td>
                                                        <td className="text-center text-capitalize">
                                                            {user.name}
                                                        </td>
                                                        <td className="text-center">
                                                            {user.email}
                                                        </td>
                                                        <td className="text-center">
                                                            {user.mobile}
                                                        </td>
                                                        <td className="text-center">
                                                            <Switch
                                                                checked={user.status === 0 ? true : false}
                                                                onChange={(value) => { handleStaffCheck(value, index, user._id) }}
                                                                loading={isToggling.isToggling && isToggling.isTogglingIndex === index}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-success btn-round"
                                                                data-toggle="modal"
                                                                data-target="#StaffPasswordModal"
                                                                onClick={() => { setCurrentUserId(user._id) }}
                                                            >
                                                                <i className="fas fa-key"></i>
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-round bg-warning"
                                                                onClick={() => {
                                                                    props.history.push('/staff/order/' + user._id)
                                                                }}
                                                            >
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark btn-round "
                                                                onClick={() => {
                                                                    props.history.push('/edit_staff', { staff: user, edit: true })
                                                                }}
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <Pagination
                                    showSizeChanger
                                    onChange={(current, limit) => {
                                        if (limit !== paginationParams.limit) {
                                            setPaginationParams({
                                                ...paginationParams,
                                                limit: limit,
                                                skip: 0,
                                                current: 1
                                            });
                                        }
                                        else {
                                            setPaginationParams({
                                                ...paginationParams,
                                                skip: ((current - 1) * paginationParams.limit),
                                                current
                                            });
                                        }
                                    }}
                                    current={paginationParams.current}
                                    defaultCurrent={1}
                                    total={totalUsers}
                                />
                            </div>
                            {
                                !isLoading && staff.length === 0 && (
                                    (
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <img
                                                    src={NotFound}
                                                    className="img-fluid"
                                                    width="300"
                                                />
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <span>No Staff members Found!!</span>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <StaffPassword
                userId={currentUserId}
            />
        </div>
    )
    function handleStaffCheck(value, index, userId) {
        setIsToggling({
            ...isToggling,
            isTogglingIndex: index,
            isToggling: true
        });
        let body = {
            userId,
            status: value ? 0 : 1
        }
        let res = Helper('/staff', 'PATCH', body);
        res.then((response) => {
            if (response.error) toast.error(response.error)
            else {
                fetchStaff();
            }
        })
            .catch((error) => null)
            .finally(() => setIsToggling({
                ...isToggling,
                isTogglingIndex: null,
                isToggling: false
            }));
    }
    function fetchStaff(searchText) {
        setIsLoading(true);
        let body = {
            limit: paginationParams.limit,
            skip: paginationParams.skip,
            userType: 3,
            searchString: searchText
        }
        if (searchString === '') delete body.searchString;
        let res = Helper('/users', 'POST', body);
        res.then((response) => {
            if (response.error) toast.error(response.error);
            else {
                setStaff(response.users);
                setTotalUsers(response.totalUsers);
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false));
    }
}