import React, { useState, useEffect } from 'react';
import Spinner from '../../common/components/spinner';

//constants
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Helper from '../../common/consts/Helper';

export default function ViewData() {

    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title"> Notfication Data</h4>
                            <div className="update">
                                <Link to="/notification">
                                    <button type="button" className="btn btn-primary btn-round">Send Notification</button>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="card-header">
                            <form>
                                <div className="input-group no-border">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <i className="nc-icon nc-zoom-split"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div> */}
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className=" text-primary">
                                        <th className="text-center">
                                            Sno
                                        </th>
                                        <th className="text-center">
                                            Sent
                                        </th>
                                        <th className="text-center">
                                            Image
                                        </th>
                                        <th className="text-center">
                                            Title
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th className="text-center">
                                            All Users
                                        </th>
                                        <th className="text-center">
                                            User
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && (
                                                <tr className="col-12 text-center">
                                                    <td colSpan="9">
                                                        <Spinner />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {
                                            notifications.map((notification, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-center">
                                                            {moment(notification.createdAt).format('MMM DD, YYYY HH:mm:ss')}
                                                        </td>
                                                        <td className="text-center">
                                                            <img
                                                                className="img-fluid"
                                                                style={{ maxWidth: '250px' }}
                                                                src={notification.image}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            {notification.title}
                                                        </td>
                                                        <td style={{ maxWidth: '250px' }}>
                                                            {notification.description}
                                                        </td>
                                                        <td className="text-center">
                                                            {notification.isAll ? 'Yes' : 'No'}
                                                        </td>
                                                        <td className="text-center">
                                                            {notification.userInfo[0]?.email}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    function getNotifications() {
        setIsLoading(true);
        let res = Helper('/fetch_notification', 'POST', {});
        res.then((response) => {
            if (response?.error) {
                toast.error(response.error.toString())
            }
            else {
                setNotifications(response.notification);
            }
        })
            .catch((error) => {

            })
            .finally(() => setIsLoading(false))
    }
}