import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../common/components/spinner';
import Helper from '../../common/consts/Helper';
import { Select } from 'antd';
import { collegeDetails } from '../../common/consts/ProtaxHelpers';

const { Option } = Select;

export default function AddStudent(props) {

    const [data, setData] = useState({
        name: '',
        email: '',
        mobile: '',
        collegeName: '',
        collegeImage: '',
        discount: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [studentId, setStudentId] = useState('');
    const [fullStudent, setFullStudent] = useState({});

    let { name, email, mobile, discount, collegeImage, collegeName } = data;

    useEffect(() => {
        let { state } = props.history.location;
        if (state && state.edit) {
            setIsEdit(true);
            let { student } = state;
            setFullStudent(student);
            setData({
                ...data,
                name: student?.name,
                email: student?.email,
                mobile: student?.mobile,
                collegeName: student?.collegeName,
                collegeImage: student?.collegeImage,
                discount: student?.discount
            });
            setStudentId(student._id);
        }
    }, []);

    return (
        <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-user">
                        <div className="card-header">
                            <h5 className="card-title">
                                <span
                                    onClick={() => {
                                        props.history.push('/user')
                                    }}
                                    className="cursor-pointer"
                                >
                                    <i className="fa fa-chevron-left mr-3" />
                                </span>{isEdit ? 'Edit' : 'Add'} Student</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={addStudent}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                className="form-control text-capitalize"
                                                placeholder="Ex - John Smith"
                                                name="name"
                                                value={name}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Ex - staff@gmail.com"
                                                name="email"
                                                value={email}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Ex - 98372829"
                                                name="mobile"
                                                required
                                                value={mobile}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select College</label>
                                            <div>
                                                <Select
                                                    value={collegeName}
                                                    style={{ width: '100%' }}
                                                    onChange={(value, record) => {
                                                        setData({
                                                            ...data,
                                                            'collegeName': value,
                                                            'discount': collegeDetails[record.key].discount,
                                                            'collegeImage': collegeDetails[record.key].collegeImageUrl
                                                        })
                                                    }}
                                                >
                                                    {
                                                        collegeDetails.map((college, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    value={college.collegeName}
                                                                >{college.collegeName}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Discount $</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Discount Amount"
                                                name="discount"
                                                value={discount}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>College Image URL</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="College Image Url"
                                                name="collegeImage"
                                                value={collegeImage}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="update ml-auto mr-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-round"
                                            disabled={isLoading}
                                        >
                                            {isLoading && <Spinner />}{isEdit ? 'Edit' : 'Add'} Student
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    function addStudent(e) {
        e.preventDefault();
        setIsLoading(true);
        let { name, email, mobile, collegeImage, collegeName, discount } = data;

        let body = {
            name,
            email,
            mobile,
            collegeImage,
            collegeName,
            discount,
            userType: 4
        };

        let res;

        if (isEdit) {
            body.userId = studentId;
            res = Helper('/edit_detail_by_admin', 'POST', body, false);
        }
        else {
            res = Helper('/signup', 'POST', body, false);
        }
        res.then((response) => {
            if (response.error) toast.error(response.error);
            else {
                if (isEdit) {
                    toast.success("Student Edited succesfully!!");
                    props.history.goBack();
                }
                else {
                    toast.success("Student added succesfully!!");
                    setData({
                        ...data,
                        name: '',
                        email: '',
                        mobile: '',
                        collegeName: '',
                        collegeImage: '',
                        discount: '',
                    })
                }
            }
        })
            .catch((error) => null)
            .finally(() => setIsLoading(false))
    }
    function handleChange(e) {
        let { value, name } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }
    function getImageSrc() {
        let { image } = data;
        let url = '';
        if (typeof (image) === 'string')
            url = image
        else if (typeof (image) === 'object')
            url = URL.createObjectURL(image);
        return url
    }
    function handleImage(e) {
        let { files } = e.target;
        if (files.length > 0) {
            setData({
                ...data,
                image: files[0]
            });
        }
    }
}