import React, { Fragment, useRef } from 'react';
import { Checkbox, Divider, DatePicker } from 'antd';
import _ from 'lodash';

const { RangePicker } = DatePicker;

export default function Filter(props) {

    const closeRef = useRef();

    let { filters, setFilters, setFiltersToSend, filtersToSend } = props;

    return (
        <div class="modal fade" id="FilterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Filters</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h5>By Date</h5>
                                <RangePicker
                                    value={[filters.dateFilter.min, filters.dateFilter.max]}
                                    onChange={(value) => {
                                        if (value && value.length === 2) {
                                            setFilters({
                                                ...filters,
                                                dateFilter: {
                                                    min: value[0],
                                                    max: value[1]
                                                }
                                            })
                                        }
                                    }}
                                />
                            </div>
                            <Divider />
                        </div>
                        <div className="row">
                            {
                                Object.keys(filters).map((filter, index) => {
                                    if (filter !== 'dateFilter')
                                        return (
                                            <Fragment key={index}>
                                                <div className="col-sm-12">
                                                    <h5>{_.startCase(filter)}</h5>
                                                    {
                                                        filters[filter].map((checklist, ci) => {
                                                            if (filter === 'checklist') {
                                                                return (
                                                                    <div key={ci} className="col-12">
                                                                        <Checkbox className="text-capitalize" value={checklist.checked} onChange={(e) => { handleChange(filter, ci, e.target.checked) }}>{checklist.title}</Checkbox>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <Checkbox key={ci} className="text-capitalize" value={checklist.checked} onChange={(e) => { handleChange(filter, ci, e.target.checked) }}>{checklist.title}</Checkbox>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <Divider />
                                            </Fragment>
                                        )
                                    else return null
                                })
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            ref={closeRef}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={submit}
                        >
                            Save changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    function submit() {
        let preferredContactMethodFilter = [];
        let preferredReviewMethodFilter = [];
        let genderFilter = [];
        let maritalStatusFilter = [];
        let checklistFilter = [];
        let minDate = filters.dateFilter.min;
        let maxDate = filters.dateFilter.max;

        let preferredContactMethod = filters.preferredContactMethod.filter(e => e.checked);
        for (const element of preferredContactMethod) {
            preferredContactMethodFilter.push(element.value)
        }
        let preferredReviewMethod = filters.preferredReviewMethod.filter(e => e.checked);
        for (const element of preferredReviewMethod) {
            preferredReviewMethodFilter.push(element.value)
        }
        let gender = filters.gender.filter(e => e.checked);
        for (const element of gender) {
            genderFilter.push(element.value)
        }
        let maritalStatus = filters.maritalStatus.filter(e => e.checked);
        for (const element of maritalStatus) {
            maritalStatusFilter.push(element.value)
        }
        let checklist = filters.checklist.filter(e => e.checked);
        for (const element of checklist) {
            checklistFilter.push(element.title)
        }

        setFiltersToSend({
            ...filtersToSend,
            preferredContactMethodFilter,
            preferredReviewMethodFilter,
            genderFilter,
            maritalStatusFilter,
            checklistFilter,
            minDate,
            maxDate
        });

        closeRef.current.click()
    }
    function handleChange(key, index, checked) {
        let newFilter = filters[key].slice();
        newFilter[index].checked = checked;
        setFilters({
            ...filters,
            [key]: newFilter
        });
    }
}