import {
  PICKUP_HANDLE_CHANGE,
  PICKUP_RESET_STATE,
  SET_EDIT_STATE,
  HANDLE_MULTIPLE_CHANGE
} from '../actions/types';

import {
  EmptyPickupState
} from './EmptyPickupState';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);


const initialState = {
  ...EmptyPickupState
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PICKUP_HANDLE_CHANGE:
      return {
        ...state,
        [action.name]: action.payload
      };
    case HANDLE_MULTIPLE_CHANGE:
      return {
        ...state,
        ...action.payload
      }
    case PICKUP_RESET_STATE:
      return {
        ...state,
        ...EmptyPickupState,
        spouseSlipsChecklist: [
          ...EmptyPickupState.spouseSlipsChecklist
        ],
        slipsChecklist: [
          ...EmptyPickupState.slipsChecklist
        ],
        otherDocChecklist: [
          ...EmptyPickupState.otherDocChecklist
        ],
        spouseOtherDocChecklist: [
          ...EmptyPickupState.spouseOtherDocChecklist
        ],
        receiptsChecklist: [
          ...EmptyPickupState.receiptsChecklist
        ],
        spouseReceiptsChecklist: [
          ...EmptyPickupState.spouseReceiptsChecklist
        ],
        spouseInfo: {
          ...EmptyPickupState.spouseInfo
        },
        tempDependantsInfo: {
          ...EmptyPickupState.tempDependantsInfo
        },
        dependants: [
          ...EmptyPickupState.dependants
        ]
      }
    case SET_EDIT_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}