import React from "react";

import { Modal } from "antd";

export default function TFormViewer(props) {
  const { visible, onClose, fields } = props;
  return (
    <Modal visible={visible} onCancel={onClose}>
      <div>
        {fields?.map((field, index) => {
          return (
            <div key={index}>
              <div className="text-bold">{field?.title}</div>
              <div className="mt-1 mb-1">
                {generateFields(field?.fields)}
                {field?.other && field?.otherFields?.length > 0 ? (
                  <div className="text-bold">{field?.otherLabel}</div>
                ) : null}
                {field?.other &&
                  field?.otherFields?.map((oField, oIndex) => {
                    if (oField?.label || oField?.value) {
                      return (
                        <div key={oIndex} style={{ marginTop: "0.2rem" }}>
                          <div>{oField.label}</div>
                          <div>{oField.value}</div>
                        </div>
                      );
                    } else return null;
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );

  function generateFields(fields) {
    return (
      <div>
        {fields?.map((input, inpIndex) => {
          if (input?.value) {
            return (
              <div key={inpIndex} style={{ marginTop: "0.2rem" }}>
                <div>{input.label}</div>
                <div>{input.value}</div>
              </div>
            );
          }
          if (input?.type === "sub" && input?.subFields?.length > 0) {
            return generateFields(input?.subFields);
          } else return null;
        })}
      </div>
    );
  }
}
