import React, { Fragment } from 'react';

//ant d components
import { DatePicker, Select } from 'antd';

//helpers
import { allGenders } from '../../common/consts/ProtaxHelpers';

//redux imports
import { connect } from 'react-redux';
import { handleChange } from '../../redux/actions/PickupActions';

//consts
import moment from 'moment';

const { Option } = Select;

function BasicDetails(props) {

    let {

        //basic details
        gender,
        firstName,
        middleName,
        lastName,
        sin,
        dob,
        address,
        city,
        postalCode,
        province,


        //functions
        handleChange,
    } = props;

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <span className="title">Basic Details</span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <label>Gender</label>
                        <Select
                            placeholder="Gender"
                            className="text-capitalize w-100"
                            value={gender}
                            onChange={(value) => {
                                handleRadios('gender', value);
                            }}
                        >
                            {
                                allGenders.map((gender, index) => {
                                    return (
                                        <Option className="text-capitalize" key={index} value={gender.value}>{gender.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Legal Name</label>
                        <input
                            value={firstName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Legal Name"
                            required
                            name="firstName"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Middle Name</label>
                        <input
                            value={middleName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Middle Name"
                            required
                            name="middleName"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                        <label>Last Name</label>
                        <input
                            value={lastName}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Last Name"
                            required
                            name="lastName"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>SIN</label>
                        <input
                            value={sin}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="SIN"
                            required
                            name="sin"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>DOB</label>
                        <DatePicker
                            value={moment(dob, 'YYYY-MM-DD')}
                            style={{ width: '100%', height: '40px' }}
                            onChange={(value, datestring) => {
                                handleDates('dob', datestring)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Address</label>
                        <input
                            value={address}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Address"
                            required
                            name="address"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>City</label>
                        <input
                            value={city}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="City"
                            required
                            name="city"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Postal Code</label>
                        <input
                            value={postalCode}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Postal Code"
                            required
                            name="postalCode"
                            onChange={handleText}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label>Province</label>
                        <input
                            value={province}
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Province"
                            required
                            name="province"
                            onChange={handleText}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )

    function handleRadios(name, value) {
        handleChange(name, value)
    }
    function handleDates(name, value) {
        handleChange(name, value)
    }
    function handleText(e) {
        let { value, name } = e.target;
        handleChange(name, value);
    }
}


const mapStateToProps = () => state => ({

    //basic details
    gender: state.Pickup.gender,
    firstName: state.Pickup.firstName,
    middleName: state.Pickup.middleName,
    lastName: state.Pickup.lastName,
    sin: state.Pickup.sin,
    dob: state.Pickup.dob,
    address: state.Pickup.address,
    city: state.Pickup.city,
    postalCode: state.Pickup.postalCode,
    province: state.Pickup.province,


});
const mapDispatchToProps = () => dispatch => ({
    handleChange: (name, value) => dispatch(handleChange(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);