import React, { useState, useRef } from 'react';

//components
import { Slider } from 'antd';
import Spinner from '../spinner';

//helpers
import _ from 'lodash';
import Helper from '../../consts/Helper';
import { toast } from 'react-toastify';

export default function OrderPercent(props) {

    let { fetchOrders, setOrderPercent, orderPercent } = props;

    const closeRef = useRef();

    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <div class="modal fade" id="OrderPercentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Order Completion Percent</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-12 mb-3">
                                <h6 className="text-bold">File - {orderPercent.fullName}</h6>
                            </div>
                            <div className="col-12">
                                <label>Select Status</label>
                            </div>
                            <div className="col-12">
                                <Slider
                                    value={orderPercent.percent}
                                    onChange={(value) => {
                                        setOrderPercent({
                                            ...orderPercent,
                                            percent: value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            ref={closeRef}
                        >Close</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                submit()
                            }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spinner /> : null}Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    function submit() {
        if (!orderPercent.percent) {
            toast.error("Please slide to select the value!!");
        }
        else {
            setIsSubmitting(true);
            let res = Helper('/complete', 'POST', {
                orderId: orderPercent.id,
                complete: orderPercent.percent
            })
            res.then((response) => {
                if (response.error) toast.error(response.error)
                else {
                    toast.success(response.message)
                    setOrderPercent({
                        ...orderPercent,
                        id: '',
                        percent: ''
                    })
                    fetchOrders();
                    closeRef.current.click();
                }
            })
                .catch((error) => null)
                .finally(() => setIsSubmitting(false))
        }
    }
}