import React, { useState, useRef } from 'react';

//components
import { Slider } from 'antd';
import Spinner from '../spinner';

//helpers
import _ from 'lodash';
import Helper from '../../consts/Helper';
import { toast } from 'react-toastify';

export default function StaffPassword(props) {

    let { userId } = props;

    const closeRef = useRef();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const [data, setData] = useState({
        password: '',
        confirmPassword: ''
    })

    return (
        <div class="modal fade" id="StaffPasswordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Staff Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-control p-0 m-0" style={{ borderWidth: 0 }}>
                                    <label>Password</label>
                                    <div className="input-group">
                                        <input
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            className="form-control"
                                            placeholder="**********"
                                            name="password"
                                            required
                                            value={data.password}
                                            onChange={handleChange}
                                        />
                                        <div
                                            class="input-group-append cursor-pointer"
                                            onClick={() => { setIsPasswordVisible(previousState => !previousState) }}
                                        >
                                            <span class="input-group-text">
                                                {
                                                    isPasswordVisible ? (
                                                        <i className="fa fa-eye" />
                                                    ) : (
                                                            <i className="fas fa-eye-slash" />
                                                        )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-control p-0 m-0" style={{ borderWidth: 0 }}>
                                    <label>Confirm Password</label>
                                    <div className="input-group">
                                        <input
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            className="form-control"
                                            placeholder="**********"
                                            name="confirmPassword"
                                            required
                                            value={data.confirmPassword}
                                            onChange={handleChange}
                                        />
                                        <div
                                            class="input-group-append cursor-pointer"
                                            onClick={() => { setIsPasswordVisible(previousState => !previousState) }}
                                        >
                                            <span class="input-group-text">
                                                {
                                                    isPasswordVisible ? (
                                                        <i className="fa fa-eye" />
                                                    ) : (
                                                            <i className="fas fa-eye-slash" />
                                                        )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            ref={closeRef}
                        >Close</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                submit()
                            }}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spinner /> : null}Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    function handleChange(e) {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }
    function submit() {
        let { password, confirmPassword } = data;
        if (password !== confirmPassword) {
            toast.error("Password Doesn't Matched!!");
        }
        else {
            setIsSubmitting(true);
            let res = Helper('/set_password', 'POST', {
                password,
                userId: userId
            })
            res.then((response) => {
                if (response.error) toast.error(response.error)
                else {
                    toast.success(response.message);
                    setData({
                        ...data,
                        password: '',
                        confirmPassword: ''
                    })
                    closeRef.current.click();
                }
            })
                .catch((error) => null)
                .finally(() => setIsSubmitting(false))
        }
    }
}