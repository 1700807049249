import React, { useEffect, useState, useCallback } from "react";
import { Pagination, Checkbox, Input } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { Tabs } from "antd";

//custom components
import OrderPercent from "../../common/components/orderPercent";
import OrderStatus from "../../common/components/orderStatus";
import Spinner from "../../common/components/spinner";
import { AimOutlined } from "@ant-design/icons";

//consts
import { toast } from "react-toastify";
import moment from "moment";
import FilterState from "./FilterState";

//helpers
import {
  StatusApi,
  StatusString,
  totalAmount,
} from "../../common/consts/ProtaxHelpers";
import Helper from "../../common/consts/Helper";
import _ from "lodash";

//images
import NotFound from "../../assets/images/notFound.png";
import FinalDocModal from "./FinalDocModal";
import OtherDocModal from "./OtherDocModal";

const { Search } = Input;
const { TabPane } = Tabs;
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function Order(props) {
  let { id } = useParams();

  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  const [paginationParams, setPaginationParams] = useState({
    limit: 10,
    skip: 0,
    current: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [totalUsers, setTotalOrders] = useState(0);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState("1");
  const [filters, setFilters] = useState(FilterState);
  const [assignStaff, setAssignStaff] = useState([]);

  // to handle tab route
  const query = useQuery();
  const tabQuery = query.get("tab");
  useEffect(() => {
    if (tabQuery) {
      setCurrentTab(tabQuery);
    }
  }, [tabQuery]);
  //to update order status    => modal
  const [currentStatus, setCurrentStatus] = useState("");
  const [orderId, setOrderId] = useState("");

  //order percentage variables
  const [orderPercent, setOrderPercent] = useState({
    percent: "",
    id: "",
    fullName: "",
  });

  const [isChargingUser, setIsChargingUser] = useState({
    isCharging: false,
    index: null,
  });

  const [finalDoc, setFinalDoc] = useState({
    title: "",
    document: null,
  });
  const [currentOrder, setCurrentOrder] = useState({});

  //fullname
  const [orderFullName, setOrderFullName] = useState("");

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    searchOrder(search);
  }, [search]);

  function searchOrder(searchString) {
    let value = searchString.toLowerCase();
    if (value === "") {
      setOrders(allOrders);
    } else {
      let searchedOrders = allOrders.filter(
        (e) =>
          e.firstName?.toLowerCase().includes(value) ||
          e.sin?.includes(value) ||
          e?.userInfo[0]?.mobile?.includes(value)
      );
      setOrders(searchedOrders);
    }
  }

  function renderTableHeader() {
    return (
      <thead className="text-primary table-sticky">
        <th></th>
        <th className="text-center">Sno</th>
        <th className="text-center">SIN</th>
        <th>Date</th>
        <th>Name</th>
        <th>Mobile</th>
        <th>Status</th>
        <th>Amount</th>
        <th>Refund</th>
        <th>Is Student</th>
        <th className="text-center">Client documents</th>
        <th>Tax return status</th>
        <th>Percent</th>
        <th>Charge User</th>
        <th>Upload 183 / Authorisation</th>
        <th>Upload client’s copy</th>
        <th>View additional documents</th>
      </thead>
    );
  }

  function getStatusCondition(e) {
    if (currentTab === "1") {
      return (
        e.status === StatusApi["pending"] ||
        e.status === StatusApi["work in progress"]
      );
    } else if (currentTab === "2") {
      return e.status === StatusApi["completed"];
    } else if (currentTab === "3") {
      return e.status === StatusApi["file e filed"];
    } else return false;
  }

  function renderTableBody() {
    const ordersToRender = orders?.filter(getStatusCondition);
    return (
      <tbody>
        {isLoading && (
          <tr className="col-12 text-center">
            <td colSpan="6">
              <Spinner />
            </td>
          </tr>
        )}
        {ordersToRender.map((order, index) => {
          let refundAmount = getAmountToRefund(
            order.payment,
            order.totalAmount,
            order.baseOrder
          );
          const isEdited = order?.isStaffView;
          return (
            <tr key={index}>
              <td>
                {isEdited && (
                  <div
                    style={{
                      transform: "scale(1.3)",
                    }}
                  >
                    <AimOutlined size={25} color={""} />
                  </div>
                )}
              </td>
              <td className="text-center">{index + 1}</td>
              <td className="text-center">{order.sin}</td>
              <td>{moment(order.createdAt).format("Do MMMM, YYYY")}</td>
              <td className="text-capitalize">
                {`${order.firstName} ${order.lastName}`}
              </td>
              <td className="text-capitalize">
                {order?.userInfo[0]?.mobile || " - "}
              </td>
              <td className="text-bold text-uppercase">
                {StatusString[order.status]}
              </td>
              <td className="text-uppercase">
                ${" "}
                {totalAmount(
                  order?.baseOrder?.order,
                  order?.baseOrder?.discount
                )}
              </td>
              <td className="text-bold">
                ${" "}
                {Math.sign(refundAmount) == -1 ? Math.abs(refundAmount) : " - "}
              </td>
              <td>{order?.userInfo[0]?.userType === 4 ? "YES" : "NO"}</td>
              <td className="text-center">
                <button
                  onClick={() => {
                    props.history.push("/order_view", {
                      order: order,
                    });
                  }}
                  type="button"
                  className="btn btn-primary btn-round bg-warning"
                >
                  <i className="fas fa-eye"></i>
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary btn-round"
                  data-toggle="modal"
                  data-target="#OrderStatusModal"
                  onClick={() => {
                    setCurrentStatus(order.status);
                    setOrderId(order._id);
                    setOrderFullName(`${order?.firstName} ${order?.lastName}`);
                  }}
                >
                  <i className="fas fa-certificate"></i>
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success btn-round"
                  data-toggle="modal"
                  data-target="#OrderPercentModal"
                  onClick={() => {
                    setOrderPercent({
                      ...orderPercent,
                      percent: order.percentageCompleted,
                      id: order._id,
                      fullName: `${order?.firstName} ${order?.lastName}`,
                    });
                  }}
                >
                  <i className="fas fa-thermometer-three-quarters"></i>
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success btn-round"
                  onClick={() => {
                    if (order?.appVersion) {
                      chargeUser(order._id, index, order);
                    } else {
                      chargeWebUser(order._id, index, order);
                    }
                  }}
                  disabled={
                    isChargingUser.isCharging && isChargingUser.index === index
                  }
                >
                  {isChargingUser.isCharging &&
                  isChargingUser.index === index ? (
                    <Spinner />
                  ) : (
                    <i className="fas fa-file-alt"></i>
                  )}
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-info btn-round"
                  onClick={() => {
                    let route = "/document_upload/";
                    const isWeb = true;
                    if (isWeb) {
                      route = "/document_upload_web/";
                    }
                    props.history.push(route + order._id, {
                      document1: order?.document1,
                      signature1: isWeb
                        ? order?.documentSignature1
                        : order.signature1,

                      document2: order?.document2,
                      signature2: isWeb
                        ? order?.documentSignature2
                        : order.signature2,

                      document3: order?.document3,
                      signature3: isWeb
                        ? order?.documentSignature3
                        : order.signature3,

                      document4: order?.document4,
                      signature4: isWeb
                        ? order?.documentSignature4
                        : order.signature4,

                      document5: order?.document5,
                      signature5: isWeb
                        ? order?.documentSignature5
                        : order.signature5,

                      fullName: `${order?.firstName} ${order?.lastName}`,
                    });
                  }}
                >
                  <i className="fas fa-file-alt"></i>
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-dark btn-round"
                  data-toggle="modal"
                  data-target="#uploadFinalDoc"
                  onClick={() => {
                    setOrderId(order._id);
                    setFinalDoc({
                      title: order.finalDocumentTitle,
                      document: order.orderDocument,
                      fullName: `${order?.firstName} ${order?.lastName}`,
                      spouseDocument: order.spouseDocument,
                    });
                    setCurrentOrder({
                      ...order,
                    });
                  }}
                >
                  <i className="fas fa-file-alt"></i>
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-warning btn-round"
                  data-toggle="modal"
                  data-target="#uploadOtherDoc"
                  onClick={() => {
                    setOrderId(order._id);
                    setCurrentOrder({
                      ...currentOrder,
                      ...order,
                    });
                  }}
                >
                  <i class="fas fa-file"></i>
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title text-capitalize">
                {" "}
                Staff Orders - {userInfo.name}
              </h4>
            </div>
            <div className="card-header">
              <div className="input-group no-border">
                <Search
                  placeholder="input search text"
                  allowClear
                  enterButton="Search"
                  size="large"
                  onSearch={searchOrder}
                />
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <Tabs
                    defaultActiveKey="1"
                    activeKey={currentTab}
                    onChange={(key) => {
                      props.history.push(`?tab=${key}`);
                    }}
                  >
                    <TabPane tab="Orders" key="1">
                      <div className="table-responsive table-scroll">
                        <table className="table">
                          {renderTableHeader()}
                          {renderTableBody()}
                        </table>
                      </div>
                    </TabPane>
                    <TabPane tab="Completed" key="2">
                      <div className="table-responsive table-scroll">
                        <table className="table">
                          {renderTableHeader()}
                          {renderTableBody()}
                        </table>
                      </div>
                    </TabPane>
                    <TabPane tab="File E Filed" key="3">
                      <div className="table-responsive table-scroll">
                        <table className="table">
                          {renderTableHeader()}
                          {renderTableBody()}
                        </table>
                      </div>
                    </TabPane>
                  </Tabs>
                </table>
                {/* {
                                    orders.length > 0 && (
                                        <Pagination
                                            showSizeChanger
                                            onChange={(current, limit) => {
                                                if (limit !== paginationParams.limit) {
                                                    setPaginationParams({
                                                        ...paginationParams,
                                                        limit: limit,
                                                        skip: 0,
                                                        current: 1
                                                    });
                                                }
                                                else {
                                                    setPaginationParams({
                                                        ...paginationParams,
                                                        skip: ((current - 1) * paginationParams.limit),
                                                        current
                                                    });
                                                }
                                            }}
                                            current={paginationParams.current}
                                            defaultCurrent={1}
                                            total={totalUsers}
                                        />
                                    )
                                } */}
              </div>
              {!isLoading && orders.length === 0 && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <img src={NotFound} className="img-fluid" width="300" />
                  </div>
                  <div className="col-md-12 text-center">
                    <span>No Orders Found!!</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <OrderStatus
        fetchOrders={fetchOrders}
        setCurrentStatus={setCurrentStatus}
        setOrderId={setOrderId}
        orderId={orderId}
        currentStatus={currentStatus}
        orderFullName={orderFullName}
      />
      <OrderPercent
        fetchOrders={fetchOrders}
        setOrderPercent={setOrderPercent}
        orderPercent={orderPercent}
      />

      <FinalDocModal
        fetchOrders={fetchOrders}
        orderId={orderId}
        finalDoc={finalDoc}
        spouseDetails={currentOrder}
      />
      <OtherDocModal
        order={currentOrder}
        fetchOrders={fetchOrders}
        orderId={orderId}
      />
    </div>
  );
  function getAmountToRefund(payment, totalAmount, baseOrder) {
    let transactions = payment?.slice() || [];
    let totalPaid = 0;
    let PaymentAmount = 0;
    for (const transaction of transactions) {
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }
    totalPaid = +totalPaid / 100;
    PaymentAmount = +totalAmount - +totalPaid - +(baseOrder?.discount || 0);
    return PaymentAmount.toFixed(2);
  }
  function handleAssignStaffCheck(e, id) {
    let { checked } = e.target;
    if (checked) {
      setAssignStaff([...assignStaff, id]);
    } else {
      let newAssignStaff = assignStaff.slice();
      let index = newAssignStaff.findIndex((e) => e === id);
      if (index !== -1) {
        newAssignStaff.splice(index, 1);
        setAssignStaff(newAssignStaff);
      }
    }
  }

  function testChargeUserCases(order) {
    let payment = order?.payment?.slice() || [];
    let totalPaid = 0;
    let myStatus = "";
    let returnStatus = false;
    for (const transaction of payment) {
      if (transaction.paymentStatus === 2) {
        myStatus = "expired";
      }
      if (
        transaction.status === "succeeded" &&
        transaction.paymentStatus !== 2
      ) {
        totalPaid += +transaction.amount;
      }
    }
    totalPaid = (totalPaid / 100).toFixed(2);
    if (totalPaid == order.totalAmount) {
      returnStatus = true;
    } else if (totalPaid !== order.totalAmount) {
      returnStatus = false;
      toast.error("User still has pending Amount!!");
    } else if (myStatus === "expired") {
      toast.error(
        "One or more payments are expired, Please tell the user to Reauthorize Payments!!"
      );
      returnStatus = false;
    }

    return returnStatus;
  }
  function chargeUserDisability() {
    //loop payment array
    //check status succeeded and 1
    //sum amount
    //condition summed amount must be equal to totalamount
  }

  async function checkOrderCalculation(id) {
    try {
      const response = await Helper("/order_calculation", "POST", {
        orderId: id,
      });

      if (response?.order && response?.order?.length > 0) {
        const order = response.order[0];
        if (order?.userNeedToPay > 0) {
          alert("User has pending amount to pay");
          return false;
        } else if (order?.refundAmount > 0) {
          alert("User has pending amount to refund, Can't Charge");
          return false;
        } else
          return {
            paymentIntentId: order?.paymentInfo?.id,
          };
      }
    } catch (error) {}
  }

  function chargeWebUser(id, index, order) {
    let confirmation = window.confirm(
      "Are you sure you want to charge the user for this order?"
    );
    if (confirmation) {
      if (testChargeUserCases(order)) {
        setIsChargingUser({
          ...isChargingUser,
          isCharging: true,
          index: index,
        });
        let body = {
          orderId: id,
        };
        const urlToHit = order?.appVersion
          ? "/capture_payment_intent"
          : "/capture_payment";
        let res = Helper(urlToHit, "POST", body);
        res
          .then((response) => {
            if (response.error) {
              toast.error(response?.error?.toString());
            } else {
              toast.success("Payment has been successfully charged");
            }
          })
          .catch(() => null)
          .finally(() => {
            setIsChargingUser({
              ...isChargingUser,
              isCharging: false,
              index: null,
            });
          });
      }
    }
  }
  async function chargeUser(id, index, order) {
    let confirmation = window.confirm(
      "Are you sure you want to charge the user for this order?"
    );
    if (confirmation) {
      setIsChargingUser({
        ...isChargingUser,
        isCharging: true,
        index: index,
      });
      const isChargable = await checkOrderCalculation(id);
      let body = {
        orderId: id,
      };
      let intentBody = {
        orderId: id,
        paymentIntentId: isChargable?.paymentIntentId,
      };
      if (isChargable) {
        const isMobileApp = order?.appVersion;
        const urlToHit = isMobileApp
          ? "/capture_payment_intent"
          : "/capture_payment";
        let res = Helper(urlToHit, "POST", isMobileApp ? intentBody : body);
        res
          .then((response) => {
            if (response.error) {
              toast.error(response?.error?.toString());
            } else if (response?.raw?.message) {
              toast.error(response?.raw?.message?.toString());
            } else {
              toast.success("Payment has been successfully charged");
            }
          })
          .catch(() => null)
          .finally(() => {
            setIsChargingUser({
              ...isChargingUser,
              isCharging: false,
              index: null,
            });
          });
      } else {
        setIsChargingUser({
          ...isChargingUser,
          isCharging: false,
          index: null,
        });
      }
    }
  }
  function fetchOrders() {
    setIsLoading(true);
    let body = {
      userId: id,
    };
    let res = Helper("/fetch_staff_by_id", "POST", body);
    res
      .then((response) => {
        if (response.error) toast.error(response.error);
        else {
          setOrders(response.orderInfo);
          setAllOrders(response.orderInfo);
          setUserInfo(response.userInfo[0]);
        }
      })
      .catch((error) => null)
      .finally(() => setIsLoading(false));
  }
}
